import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, CircularProgress, Fab, Button } from "@mui/material";
import {
  ArrowUpward as ArrowUpwardIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Memory as MemoryIcon,
} from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { format } from "date-fns";
import {
  fetchAnonymizedChatSessions,
  fetchChatSessions,
  fetchComments,
  fetchMemoryData,
  setConversationShadowBanned,
} from "../../../redux/actions/conversationsActions";
import { Message } from "../../../redux/reducers/conversationsReducer";
import { getFullNameFromUserName, getTargetId } from "../../../utils/render";
import { debounce } from "lodash";
import SearchBar from "./conversation-view/SearchBar";
import DetailsSection from "./conversation-view/DetailsSection";
import DateDrawer from "./conversation-view/DateDrawer";
import FeedbackSection from "./conversation-view/FeedbackSection";
import MessageList from "./conversation-view/MessageList";
import FilterToolbar from "./conversation-view/FilterToolbar"; // renamed from TagToolbar
import MemoryModal from "./conversation-view/MemoryModal";
import { TriggerMemoryButton } from "./conversation-view/TriggerMemoryButton";

interface ConversationsViewProps {
  canReadConversation?: boolean;
}

// Helper to create a map of chatSessionId → the entire session object
function createChatSessionMap(sessions: any[]) {
  return sessions.reduce((acc, session) => {
    acc[session.id] = session;
    return acc;
  }, {} as Record<string, any>);
}

const ConversationView: React.FC<ConversationsViewProps> = ({
  canReadConversation,
}) => {
  const { conversationId } = useParams<{ conversationId: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [showRealData, setShowRealData] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [showScrollToTop, setShowScrollToTop] = useState<boolean>(false);
  const [highlightedItemId, setHighlightedItemId] = useState<string | null>(
    null
  );
  const [highlightedCommentId, setHighlightedCommentId] = useState<
    string | null
  >(null);

  const highlightTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [showDetails, setShowDetails] = useState<boolean>(true);

  const [dates, setDates] = useState<string[]>([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const targetId = searchParams.get("target_id");

  // Redux conversation & transcript
  const conversation = useSelector((state: RootState) =>
    state.conversations?.conversations?.find((c) => c.id === conversationId)
  );
  const transcript = useSelector((state: RootState) =>
    state.conversations?.transcripts?.find(
      (c) => c.conversation_id === conversationId
    )
  );

  // Memory data
  const memoryData = useSelector((state: RootState) =>
    conversationId
      ? state.conversations.memoryData?.[conversationId]
      : undefined
  );
  const loadingMemory = useSelector((state: RootState) =>
    conversationId
      ? state.conversations.loadingMemoryData?.[conversationId]
      : false
  );

  const { user: adminUser } = useSelector((state: RootState) => state.base);
  const canViewUser = adminUser?.scopes?.includes("userview:view");
  const canViewMemory = adminUser?.scopes?.includes("memoryview:view");

  // Comments
  const comments = useSelector(
    (state: RootState) => state.conversations.comments
  );

  // Basic highlight helper
  const highlightItem = useCallback((itemId: string) => {
    setHighlightedItemId(itemId);
    if (highlightTimeoutRef.current) {
      clearTimeout(highlightTimeoutRef.current);
    }
    highlightTimeoutRef.current = setTimeout(() => {
      setHighlightedItemId(null);
    }, 2000);
  }, []);

  // Searching
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchResults, setSearchResults] = useState<number[]>([]);
  const [currentSearchIndex, setCurrentSearchIndex] = useState(0);

  const [preventTargetScroll, setPreventTargetScroll] = useState(false);

  // Date drawer visibility
  const [showDateDrawer, setShowDateDrawer] = useState(true);

  // Shadow ban helper
  const handleShadowBan = (newValue: boolean) => {
    if (!conversationId) return;
    dispatch(
      setConversationShadowBanned({
        conversationId,
        newValue,
      })
    )
      .then(() => {
        toast.success(
          `Successfully updated shadow_banned status to ${newValue}`
        );
      })
      .catch(() => {
        toast.error("Failed to update shadow banned value");
      });
  };

  // Memory modal
  const [memoryOpen, setMemoryOpen] = useState(false);
  const handleOpenMemoryModal = async () => {
    if (!conversationId) return;
    setMemoryOpen(true);
    // If we don't have memory data and not currently loading, fetch it
    if (!memoryData && !loadingMemory) {
      await dispatch(fetchMemoryData(conversationId));
    }
  };
  const loading = useSelector(
    (state: RootState) => state.conversations.loading
  );

  const loadingAllTranscripts = useSelector(
    (state: RootState) => state.conversations.loadingAllTranscripts
  );

  // Check if no conversation found
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tId = queryParams.get("target_id");

    if ((!conversation && canReadConversation) || loading) {
      // If we can read conversation but it doesn’t exist, redirect
      if (conversationId) {
        const url = tId
          ? `/dashboard/conversations?conversation_id=${conversationId}&target_id=${tId}`
          : `/dashboard/conversations?conversation_id=${conversationId}`;
        navigate(url);
      } else {
        navigate("/dashboard/conversations");
      }
    }

    if ((!transcript && !canReadConversation) || loadingAllTranscripts) {
      // If we can't read conversation, but there's no transcript, redirect
      if (conversationId) {
        const url = tId
          ? `/dashboard/conversations?conversation_id=${conversationId}&target_id=${tId}`
          : `/dashboard/conversations?conversation_id=${conversationId}`;
        navigate(url);
      } else {
        navigate("/dashboard/conversations");
      }
    }
  }, [
    conversation,
    conversationId,
    navigate,
    transcript,
    canReadConversation,
    location.search,
    loadingAllTranscripts,
    loading,
  ]);

  // Debounced fetch
  const debouncedFetchComments = useMemo(
    () => debounce((id: string) => dispatch(fetchComments(id)), 300),
    [dispatch]
  );
  const debouncedFetchChatSessions = useMemo(
    () => debounce((id: string) => dispatch(fetchChatSessions(id)), 300),
    [dispatch]
  );
  const debouncedFetchAnonymizedChatSessions = useMemo(
    () =>
      debounce((id: string) => dispatch(fetchAnonymizedChatSessions(id)), 300),
    [dispatch]
  );

  const memoizedConversationId = useMemo(
    () => conversationId,
    [conversationId]
  );

  useEffect(() => {
    if (memoizedConversationId) {
      debouncedFetchComments(memoizedConversationId);
    }
    return () => debouncedFetchComments.cancel();
  }, [memoizedConversationId, debouncedFetchComments]);

  useEffect(() => {
    if (
      conversation?.id &&
      conversation.is_private === false &&
      canReadConversation
    ) {
      debouncedFetchChatSessions(conversation.id);
    }
    if (transcript?.conversation_id && transcript?.is_private === false) {
      debouncedFetchAnonymizedChatSessions(transcript.conversation_id);
    } else if (conversation?.id && conversation?.is_private === false) {
      debouncedFetchAnonymizedChatSessions(conversation.id);
    }

    return () => {
      debouncedFetchChatSessions.cancel();
      debouncedFetchAnonymizedChatSessions.cancel();
    };
  }, [
    transcript,
    conversation,
    canReadConversation,
    debouncedFetchChatSessions,
    debouncedFetchAnonymizedChatSessions,
  ]);

  // Anonymized & real sessions
  const anonymizedChatSessions = useSelector(
    (state: RootState) => state.conversations.anonymizedChatSessions
  );
  const chatSessions = useSelector(
    (state: RootState) => state.conversations.chatSessions
  );

  // Build a map sessionId → entire ChatSession object (for speaker data)
  const chatSessionMap = useMemo(() => {
    return createChatSessionMap(
      canReadConversation ? chatSessions : anonymizedChatSessions
    );
    // If you want the same speaker structure in both real & anonymized sessions,
    // it should match; otherwise you'll adapt as needed.
  }, [chatSessions, anonymizedChatSessions, canReadConversation]);

  // Loading flags

  const loadingConversation = useSelector(
    (state: RootState) => state.conversations.loadingConversation
  );
  const loadingTranscript = useSelector(
    (state: RootState) => state.conversations.loadingTranscript
  );
  const loadingComments = useSelector(
    (state: RootState) => state.conversations.loadingComments
  );

  const [listItems, setListItems] = useState<any[]>([]);
  const [idToIndex, setIdToIndex] = useState<Record<string, number>>({});
  const [dateToIndex, setDateToIndex] = useState<Record<string, number>>({});
  const itemHeights = useRef<Record<number, number>>({});

  // Build the list of messages
  useEffect(() => {
    if (conversationId) {
      // flatten all messages
      const anonAllMessages: Message[] = anonymizedChatSessions.flatMap(
        (session) =>
          session.messages.map((m) => ({
            ...m,
            chat_session_id: session.id,
          }))
      );

      const allMessagesRaw: Message[] = chatSessions.flatMap((session) =>
        session.messages.map((m) => ({
          ...m,
          chat_session_id: session.id,
        }))
      );

      const sortedMessages = allMessagesRaw.sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );
      const sortedAnonMessages = anonAllMessages.sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );

      const messagesToUse =
        canReadConversation && showRealData
          ? sortedMessages
          : sortedAnonMessages;

      const newListItems: any[] = [];
      const newIdToIndex: Record<string, number> = {};
      const newDateToIndex: Record<string, number> = {};

      let previousDate = "";
      let previousSessionId = "";
      messagesToUse.forEach((message) => {
        const messageDate = format(
          new Date(message.created_at),
          "MMMM dd, yyyy"
        );
        if (messageDate !== previousDate) {
          newListItems.push({ type: "date", date: messageDate });
          newDateToIndex[messageDate] = newListItems.length - 1;
          previousDate = messageDate;
        }
        if (message.chat_session_id !== previousSessionId) {
          newListItems.push({
            type: "session",
            sessionId: message.chat_session_id,
          });
          previousSessionId = message.chat_session_id;
        }
        const tgtId = getTargetId(
          showRealData && canReadConversation
            ? message.id
            : message.chat_message_id,
          message.chat_session_id
        );
        newListItems.push({ type: "message", message, targetId: tgtId });
        newIdToIndex[tgtId] = newListItems.length - 1;
      });

      setListItems(newListItems);
      setIdToIndex(newIdToIndex);
      setDateToIndex(newDateToIndex);
      setDates(Object.keys(newDateToIndex));
    }
  }, [
    chatSessions,
    anonymizedChatSessions,
    conversationId,
    canReadConversation,
    showRealData,
  ]);

  // Comments & mention
  const getCommentsForMessage = useCallback(
    (targetId: string) => {
      return comments.filter(
        (comment) => comment.target_id === targetId && !comment.deleted_at
      );
    },
    [comments]
  );

  // For scrolling to date
  const listRef = useRef<any>();
  const scrollToDate = (date: string) => {
    if (dateToIndex[date] !== undefined && listRef.current) {
      listRef.current.scrollToItem(dateToIndex[date], "start");
      setSelectedDate(date);
      highlightItem(date);
    }
  };

  // For scrolling to target
  useEffect(() => {
    if (targetId && !preventTargetScroll) {
      if (targetId.startsWith("cmt:")) {
        // scroll to message that has that comment
        const commentId = targetId.slice(4);
        const messageIndex = listItems.findIndex((item) => {
          if (item.type === "message") {
            const messageComments = getCommentsForMessage(item.targetId);
            return messageComments.some((c) => c.id === commentId);
          }
          return false;
        });
        if (messageIndex !== -1 && listRef.current) {
          const messageItem = listItems[messageIndex];
          listRef.current.scrollToItem(messageIndex, "start");
          highlightItem(messageItem.targetId);
          setHighlightedCommentId(commentId);
        }
      } else if (targetId.startsWith("act:")) {
        const actionId = targetId.slice(4);
        const messageIndex = listItems.findIndex((item) => {
          return (
            item.message?.responding_to === actionId ||
            item.message?.triggered_by_action_id === actionId
          );
        });
        if (messageIndex !== -1 && listRef.current) {
          const messageItem = listItems[messageIndex];
          listRef.current.scrollToItem(messageIndex, "start");
          highlightItem(messageItem.targetId);
        }
      } else if (idToIndex[targetId] !== undefined && listRef.current) {
        listRef.current.scrollToItem(idToIndex[targetId], "start");
        highlightItem(targetId);
      }
    }
  }, [
    targetId,
    idToIndex,
    listItems,
    getCommentsForMessage,
    highlightItem,
    preventTargetScroll,
  ]);

  useEffect(() => {
    return () => {
      if (highlightTimeoutRef.current) {
        clearTimeout(highlightTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // Once we do the highlight scroll, allow subsequent scrolls
    setPreventTargetScroll(false);
  }, [targetId]);

  // Scroll handle
  const handleScroll = () => {
    if (listRef.current) {
      const { scrollOffset } = listRef.current.state;
      if (scrollOffset > 300) setShowScrollToTop(true);
      else setShowScrollToTop(false);

      if (scrollOffset > 100 && showDetails) setShowDetails(false);
      else if (scrollOffset <= 100 && !showDetails) setShowDetails(true);
    }
  };
  const scrollToTop = () => {
    if (listRef.current) {
      listRef.current.scrollTo(0);
      setShowDetails(true);
    }
  };

  // Shortcut for search
  const searchInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (showSearchBar && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [showSearchBar]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "f") {
        e.preventDefault();
        setShowSearchBar(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  // Jump to search result
  const scrollToSearchResult = useCallback(
    (index: number) => {
      if (listRef.current) {
        listRef.current.scrollToItem(index, "center");
        highlightItem(listItems[index].targetId);
      }
    },
    [highlightItem, listItems]
  );

  // Perform search in message content + comment text
  const handleSearch = useCallback(() => {
    if (searchTerm.trim() === "") {
      setSearchResults([]);
      return;
    }

    const matchingIndices = listItems
      .map((item, index) => {
        if (item.type === "message") {
          if (item.message.content) {
            const content = item.message.content.toLowerCase();
            if (content.includes(searchTerm.toLowerCase())) return index;
          }
          const messageComments = getCommentsForMessage(item.targetId);
          for (const comment of messageComments) {
            if (
              comment.comment.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return index;
            }
          }
        }
        return -1;
      })
      .filter((idx) => idx !== -1);

    setSearchResults(matchingIndices);
    if (matchingIndices.length > 0) {
      setCurrentSearchIndex(0);
      scrollToSearchResult(matchingIndices[0]);
    }
  }, [searchTerm, listItems, getCommentsForMessage, scrollToSearchResult]);

  const debouncedHandleSearch = useMemo(
    () => debounce(handleSearch, 250),
    [handleSearch]
  );
  useEffect(() => {
    debouncedHandleSearch();
    return () => debouncedHandleSearch.cancel();
  }, [searchTerm, debouncedHandleSearch]);

  // Tag & commenter filtering
  const [activeTags, setActiveTags] = useState<Set<string>>(new Set());
  const [allTags, setAllTags] = useState<string[]>([]);
  const [selectedCommenterId, setSelectedCommenterId] = useState<string | null>(
    null
  );

  // Build all tags from comments
  useEffect(() => {
    const tagSet = new Set<string>();
    comments.forEach((c) => {
      if (c.tags) c.tags.forEach((t) => tagSet.add(t));
    });
    setAllTags(Array.from(tagSet));
  }, [comments]);

  // Unique commenters
  const [commenters, setCommenters] = useState<
    { id: string; full_name: string }[]
  >([]);
  useEffect(() => {
    const uniqueCommenterMap = new Map<
      string,
      { id: string; full_name: string }
    >();
    comments.forEach((comment) => {
      if (!uniqueCommenterMap.has(comment.user.id)) {
        uniqueCommenterMap.set(comment.user.id, {
          id: comment.user.id,
          full_name: getFullNameFromUserName(comment.user.name) || "Unknown",
        });
      }
    });
    setCommenters(Array.from(uniqueCommenterMap.values()));
  }, [comments]);

  const toggleTag = (tag: string) => {
    setActiveTags((prev) => {
      const newSet = new Set(prev);
      newSet.has(tag) ? newSet.delete(tag) : newSet.add(tag);
      return newSet;
    });
  };

  // Combined filter logic: messages must have a comment that matches the selected commenter
  // plus includes all active tags
  const [filterSearchResults, setFilterSearchResults] = useState<number[]>([]);
  const [currentFilterIndex, setCurrentFilterIndex] = useState(0);

  const applyFilters = useCallback(() => {
    if (activeTags.size === 0 && !selectedCommenterId) {
      setFilterSearchResults([]);
      setCurrentFilterIndex(0);
      return;
    }

    const indices: number[] = [];
    listItems.forEach((item, index) => {
      if (item.type === "message") {
        const messageComments = getCommentsForMessage(item.targetId);
        // filter by commenter if selected
        const filteredByCommenter =
          !selectedCommenterId ||
          messageComments.some((c) => c.user.id === selectedCommenterId);

        // filter by tags
        const filteredByTags =
          activeTags.size === 0 ||
          messageComments.some((c) => {
            if (!c.tags) return false;
            return Array.from(activeTags).every((t) => c.tags!.includes(t));
          });

        if (filteredByCommenter && filteredByTags) {
          indices.push(index);
        }
      }
    });
    setFilterSearchResults(indices);
    setCurrentFilterIndex(0);
    if (indices.length > 0) {
      listRef.current?.scrollToItem(indices[0], "center");
      highlightItem(listItems[indices[0]].targetId);
    }
  }, [
    activeTags,
    selectedCommenterId,
    listItems,
    getCommentsForMessage,
    highlightItem,
  ]);

  useEffect(() => {
    applyFilters();
  }, [activeTags, selectedCommenterId, applyFilters]);

  const nextFilterResult = () => {
    if (filterSearchResults.length === 0) return;
    const nextIndex = (currentFilterIndex + 1) % filterSearchResults.length;
    setCurrentFilterIndex(nextIndex);
    if (listRef.current && filterSearchResults[nextIndex] !== undefined) {
      listRef.current.scrollToItem(filterSearchResults[nextIndex], "center");
      highlightItem(listItems[filterSearchResults[nextIndex]].targetId);
    }
  };
  const prevFilterResult = () => {
    if (filterSearchResults.length === 0) return;
    const prevIndex =
      (currentFilterIndex - 1 + filterSearchResults.length) %
      filterSearchResults.length;
    setCurrentFilterIndex(prevIndex);
    if (listRef.current && filterSearchResults[prevIndex] !== undefined) {
      listRef.current.scrollToItem(filterSearchResults[prevIndex], "center");
      highlightItem(listItems[filterSearchResults[prevIndex]].targetId);
    }
  };

  // Loading
  const renderLoading =
    loading || loadingTranscript || loadingConversation || loadingComments;

  // Dynamic size
  const getItemSize = (index: number) => {
    const item = listItems[index];
    const length = listItems.length;

    if (itemHeights.current[index]) {
      // For the last item, add some padding
      return index === length - 1
        ? itemHeights.current[index] + 160
        : itemHeights.current[index];
    }

    let baseSize;
    if (item.type === "date") {
      baseSize = 40;
    } else if (item.type === "session") {
      baseSize = 100;
    } else if (item.type === "message") {
      baseSize = 129;
    } else {
      baseSize = 40;
    }
    if (index === length - 1) {
      baseSize += 160;
    }
    return baseSize;
  };

  // If there's no conversation in normal read mode, or no transcript in anonymized mode, show not found
  if (
    (canReadConversation && !conversation) ||
    (!canReadConversation && !transcript)
  ) {
    return <div>Conversation not found</div>;
  }

  const handleToggleView = () => {
    setShowRealData((prev) => !prev);
  };

  const handleViewUser = (userId: string | null) => {
    if (userId) {
      navigate(`/dashboard/users?user=${userId}`);
    }
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Box
        sx={{
          flex: showDateDrawer ? 1 : "1 0 100%",
          overflow: "hidden",
          padding: "20px",
          minWidth: showDateDrawer ? "70%" : "100%",
          transition: "all 0.3s",
          position: "relative",
        }}
      >
        <ToastContainer />

        {showSearchBar && (
          <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            searchResults={searchResults}
            currentSearchIndex={currentSearchIndex}
            handleNextResult={() => {
              if (searchResults.length === 0) return;
              const nextIndex = (currentSearchIndex + 1) % searchResults.length;
              setCurrentSearchIndex(nextIndex);
              scrollToSearchResult(searchResults[nextIndex]);
            }}
            handlePrevResult={() => {
              if (searchResults.length === 0) return;
              const prevIndex =
                (currentSearchIndex - 1 + searchResults.length) %
                searchResults.length;
              setCurrentSearchIndex(prevIndex);
              scrollToSearchResult(searchResults[prevIndex]);
            }}
            setShowSearchBar={setShowSearchBar}
            searchInputRef={searchInputRef}
          />
        )}

        <DetailsSection
          showDetails={showDetails}
          conversation={conversation}
          transcript={transcript}
          canReadConversation={canReadConversation}
          showRealData={showRealData}
          canViewUser={canViewUser}
          handleViewUser={handleViewUser}
          handleToggleView={handleToggleView}
          handleShadowBan={handleShadowBan}
          conversationId={conversationId}
        />

        {conversationId && canViewMemory && (
          <div
            style={{
              width: "100%",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "12px",
              marginBottom: "12px",
            }}
          >
            <Button
              variant="contained"
              disabled={loadingMemory}
              onClick={handleOpenMemoryModal}
              startIcon={<MemoryIcon />}
            >
              View Memory
            </Button>
            <TriggerMemoryButton conversationId={conversationId} />
          </div>
        )}

        <MemoryModal
          open={memoryOpen}
          onClose={() => setMemoryOpen(false)}
          conversationId={conversationId}
        />

        <FeedbackSection
          conversationId={conversationId || ""}
          getCommentsForMessage={getCommentsForMessage}
        />

        <FilterToolbar
          allTags={allTags}
          activeTags={activeTags}
          toggleTag={toggleTag}
          commenters={commenters}
          selectedCommenterId={selectedCommenterId}
          setSelectedCommenterId={setSelectedCommenterId}
          filterSearchResults={filterSearchResults}
          currentFilterIndex={currentFilterIndex}
          nextFilterResult={nextFilterResult}
          prevFilterResult={prevFilterResult}
        />

        {renderLoading ? (
          <Box mt={4} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography variant="h6" gutterBottom>
              Chat Messages
            </Typography>
            <MessageList
              listItems={listItems}
              getCommentsForMessage={getCommentsForMessage}
              conversationId={conversationId}
              showRealData={showRealData}
              canReadConversation={canReadConversation}
              itemHeights={itemHeights}
              listRef={listRef}
              highlightedItemId={highlightedItemId}
              highlightedCommentId={highlightedCommentId}
              searchTerm={searchTerm}
              adminUser={adminUser}
              setPreventTargetScroll={setPreventTargetScroll}
              handleScroll={handleScroll}
              getItemSize={getItemSize}
              chatSessionMap={chatSessionMap}
            />
          </>
        )}

        {canReadConversation && !showDetails && (
          <Fab
            color="warning"
            size="small"
            onClick={handleToggleView}
            sx={{
              position: "fixed",
              bottom: showScrollToTop ? "80px" : "20px",
              right: "20px",
              zIndex: 1000,
            }}
          >
            {showRealData ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </Fab>
        )}

        {showScrollToTop && (
          <Fab
            color="primary"
            size="small"
            onClick={scrollToTop}
            sx={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              zIndex: 1000,
            }}
          >
            <ArrowUpwardIcon />
          </Fab>
        )}
      </Box>

      {!conversation?.is_private && (
        <DateDrawer
          showDateDrawer={showDateDrawer}
          setShowDateDrawer={setShowDateDrawer}
          dates={dates}
          selectedDate={selectedDate}
          scrollToDate={scrollToDate}
        />
      )}
    </Box>
  );
};

export default ConversationView;
