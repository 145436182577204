import React, { useMemo } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DescriptionIcon from "@mui/icons-material/Description";

import SortableBulletList from "./asset-content/SortableBulletList";
import { BulletItem } from "./asset-content/BulletItemEditor";
import {
  AssetContent,
  AssetContentItem,
} from "../../../redux/reducers/assetReducer";

const ALLOWED_CONTENT_TYPES = ["story", "unknown", "faq_entry"] as const;
type ContentType = (typeof ALLOWED_CONTENT_TYPES)[number];

function itemsToMarkdown(items: AssetContentItem[]): string {
  return items
    .map((i) =>
      i.image_url ? `* ${i.text} [](${i.image_url})` : `* ${i.text}`
    )
    .join("\n");
}

export const ensureBulletIds = (bullets: AssetContentItem[]): BulletItem[] => {
  return bullets.map((bullet) => ({
    ...bullet,
    id:
      (bullet as BulletItem)?.id ||
      `bullet-${Date.now()}-${crypto.randomUUID()}`,
  }));
};

interface AssetContentEditorProps {
  content: AssetContent;
  index: number;
  disabled: boolean;
  onChange: (newContent: AssetContent) => void;
  onRemove?: () => void;
  onPreviewStory?: () => void;
  onViewMarkdown?: (markdown: string) => void;
}

const AssetContentEditor: React.FC<AssetContentEditorProps> = ({
  content,
  index,
  disabled,
  onChange,
  onRemove,
  onPreviewStory,
  onViewMarkdown,
}) => {
  const isStory = content.type === "story";
  const isFAQ = content.type === "faq_entry";

  const bulletItems: BulletItem[] = useMemo(() => {
    if (!disabled) {
      return ensureBulletIds(content.story_items);
    } else {
      return content.story_items as BulletItem[];
    }
  }, [content.story_items, disabled]);

  const handleSubjectChange = (newSubject: string) => {
    onChange({ ...content, subject: newSubject });
  };

  const handleNotesChange = (newNotes: string) => {
    onChange({ ...content, notes: newNotes });
  };

  const handleBulletsChange = (newBullets: AssetContentItem[]) => {
    onChange({ ...content, story_items: newBullets });
  };

  const handleRemoveBullet = (idx: number) => {
    const updated = [...content.story_items];
    updated.splice(idx, 1);
    handleBulletsChange(updated);
  };

  const handleAddBullet = () => {
    const newBullet: BulletItem = {
      id: `bullet-${Date.now()}-${crypto.randomUUID()}`,
      text: "",
      image_url: "",
    };
    handleBulletsChange([...content.story_items, newBullet]);
  };

  const handleViewMarkdownClick = () => {
    if (onViewMarkdown) {
      const md = itemsToMarkdown(content.story_items);
      onViewMarkdown(md);
    }
  };

  // EVENT HANDLERS (FAQ)
  const handleFAQTopicChange = (newTopic: string) => {
    onChange({ ...content, faq_topic: newTopic });
  };

  const handleFAQEntryChange = (newEntry: string) => {
    onChange({ ...content, faq_entry: newEntry });
  };

  // EVENT HANDLER (content type)
  const handleTypeChange = (newType: ContentType) => {
    if (newType === "story") {
      onChange({
        ...content,
        type: "story",
        subject: content.subject || "",
        story_items: content.story_items || [],
        faq_topic: "",
        faq_entry: "",
        notes: content.notes || "",
      });
    } else if (newType === "faq_entry") {
      onChange({
        ...content,
        type: "faq_entry",
        subject: "",
        story_items: [],
        faq_topic: content.faq_topic || "",
        faq_entry: content.faq_entry || "",
        notes: "",
      });
    } else {
      // unknown
      onChange({
        ...content,
        type: "unknown",
        subject: "",
        story_items: [],
        faq_topic: "",
        faq_entry: "",
        notes: "",
      });
    }
  };

  return (
    <Box sx={{ p: 2, mb: 2 }}>
      {/* Header row */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Typography variant="subtitle1">Content #{index + 1}</Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {isStory && onPreviewStory && (
            <Tooltip title="Preview Story">
              <IconButton color="primary" size="small" onClick={onPreviewStory}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          )}

          {isStory && onViewMarkdown && (
            <Tooltip title="View Markdown">
              <IconButton
                color="primary"
                size="small"
                onClick={handleViewMarkdownClick}
                sx={{ ml: 1 }}
              >
                <DescriptionIcon />
              </IconButton>
            </Tooltip>
          )}

          {!disabled && onRemove && (
            <Tooltip title="Remove entire content block">
              <IconButton
                color="error"
                onClick={onRemove}
                size="small"
                sx={{ ml: 1 }}
              >
                <DeleteOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        {/* Content Type */}
        <Box>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            Content Type
          </Typography>
          <Select
            value={content.type}
            size="small"
            disabled={disabled}
            onChange={(e) => handleTypeChange(e.target.value as ContentType)}
            sx={{ minWidth: 160 }}
          >
            {ALLOWED_CONTENT_TYPES.map((t) => (
              <MenuItem key={t} value={t}>
                {t}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      {/* STORY UI */}
      {isStory && (
        <>
          <TextField
            label="Story Subject"
            variant="outlined"
            size="small"
            fullWidth
            value={content.subject || ""}
            onChange={(e) => handleSubjectChange(e.target.value)}
            disabled={disabled}
            sx={{ mb: 2 }}
          />

          <TextField
            label="Notes"
            variant="outlined"
            size="small"
            fullWidth
            multiline
            rows={6}
            value={content.notes || ""}
            onChange={(e) => handleNotesChange(e.target.value)}
            disabled={disabled}
            sx={{ mb: 2 }}
          />

          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            Story Points
          </Typography>
          <SortableBulletList
            bullets={bulletItems}
            disabled={disabled}
            onChange={handleBulletsChange}
            onRemove={handleRemoveBullet}
            onAdd={handleAddBullet}
          />
        </>
      )}

      {/* FAQ UI */}
      {isFAQ && (
        <Box sx={{ mt: 2 }}>
          <TextField
            label="FAQ Topic"
            variant="outlined"
            size="small"
            fullWidth
            value={content.faq_topic || ""}
            onChange={(e) => handleFAQTopicChange(e.target.value)}
            disabled={disabled}
            sx={{ mb: 2 }}
          />

          <TextField
            label="FAQ Entry"
            variant="outlined"
            size="small"
            multiline
            rows={5}
            fullWidth
            value={content.faq_entry || ""}
            onChange={(e) => handleFAQEntryChange(e.target.value)}
            disabled={disabled}
          />
        </Box>
      )}
    </Box>
  );
};

export default AssetContentEditor;
