import React, { useState, useMemo } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { createUser, fetchUsers } from "../../../redux/actions/userActions";
import { AppDispatch, RootState } from "../../../redux/store";
import { RolodexPerson } from "../../../redux/reducers/rolodexReducer";
import { useNavigate } from "react-router-dom";
import { Environment } from "../../../redux/reducers/baseReducer";
import { isValidPhoneNumber } from "libphonenumber-js";

interface UserCreateDialogProps {
  rolodexPerson?: RolodexPerson;
}

const UserCreateDialog: React.FC<UserCreateDialogProps> = ({
  rolodexPerson,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newLocation, setNewLocation] = useState("");
  const [newAge, setNewAge] = useState("");
  const [newReferral, setNewReferral] = useState("");
  const [newReferralRelationship, setNewReferralRelationship] = useState("");
  const [newBackgroundInfo, setNewBackgroundInfo] = useState("");
  const [newIsFriend, setNewIsFriend] = useState<boolean>(false);
  const [newCountry, setNewCountry] = useState("");
  const [newEnvironment, setNewEnvironment] = useState("");

  // Grab users, plus loading states
  const {
    users: allUsers,
    loading,
    loadingNewUser,
  } = useSelector((state: RootState) => state.users);

  // --- If users list is empty, fetch them once the dialog is opened ---
  const handleOpenDialog = () => {
    if (allUsers.length === 0) {
      dispatch(fetchUsers());
    }

    let defaultEnvironment = "staging";
    if (rolodexPerson) {
      const fName = rolodexPerson.data?.name.first_name || "";
      const lName = rolodexPerson.data?.name.last_name || "";
      const background = rolodexPerson.data?.summary || "";
      const email = rolodexPerson.data?.email || "";
      defaultEnvironment = "demo";
      setNewFirstName(fName);
      setNewLastName(lName);
      setNewBackgroundInfo(background);
      setNewEmail(email);
    }
    setNewEnvironment(defaultEnvironment);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setNewFirstName("");
    setNewLastName("");
    setNewPhoneNumber("");
    setNewEmail("");
    setNewLocation("");
    setNewAge("");
    setNewReferral("");
    setNewReferralRelationship("");
    setNewBackgroundInfo("");
    setNewIsFriend(false);
    setNewCountry("");
    setNewEnvironment("");
  };

  // --- Check for duplicates ---
  const existingUserWithEmail = useMemo(() => {
    if (!newEmail.trim()) return false;
    return allUsers.some(
      (u) => (u.email || "").toLowerCase() === newEmail.trim().toLowerCase()
    );
  }, [allUsers, newEmail]);

  const existingUserWithName = useMemo(() => {
    if (!newFirstName.trim() || !newLastName.trim()) return false;
    return allUsers.some(
      (u) =>
        (u.name?.first_name || "").toLowerCase() ===
          newFirstName.trim().toLowerCase() &&
        (u.name?.last_name || "").toLowerCase() ===
          newLastName.trim().toLowerCase()
    );
  }, [allUsers, newFirstName, newLastName]);

  // --- Validation Functions ---
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateEmailOrPhone = (email: string, phone: string) => {
    return validateEmail(email) || isValidPhoneNumber(phone);
  };

  const areFieldsFilled = [newFirstName, newEnvironment].every(
    (field) => field.trim().length > 0
  );

  const isFormValid =
    areFieldsFilled && validateEmailOrPhone(newEmail, newPhoneNumber);

  // --- Handle form submission ---
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Prevent creation if email is already used
    if (existingUserWithEmail) {
      toast.error(
        "A user with that email already exists and cannot be created again."
      );
      return;
    }

    // Warn if a user with the same full name exists
    if (existingUserWithName) {
      if (
        !window.confirm(
          "A user with the exact same full name exists. Do you still want to create this user?"
        )
      ) {
        return;
      }
    }

    const userPayload: any = {
      first_name: newFirstName,
      environment: newEnvironment,
      last_name: newLastName || null,
      phone_number: newPhoneNumber || null,
      email: newEmail || null,
      location: newLocation || null,
      country: newCountry || null,
      age: newAge ? Number(newAge) : null,
      referral: newReferral || null,
      referral_relationship: newReferralRelationship || null,
    };

    // If a rolodex person is passed in, add background & is_friend
    if (rolodexPerson) {
      userPayload.background = newBackgroundInfo || null;
      userPayload.is_friend = newIsFriend;
    }

    // Build the payload for createUser
    const createUserPayload = rolodexPerson
      ? { user: userPayload, create_conversation: true }
      : { user: userPayload };

    dispatch(createUser(createUserPayload))
      .unwrap()
      .then((user) => {
        handleCloseDialog();
        navigate(`/dashboard/users/${user.id}`, {
          state: { toastMessage: "Created New User Successfully!" },
        });
      })
      .catch(() => {
        toast.error("Failed to create new user.");
      });
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        sx={{ py: 1 }}
        onClick={handleOpenDialog}
      >
        Create User
      </Button>

      <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle>Create New User</DialogTitle>

        {/* If fetching user list or creating user, show loader; otherwise show form */}
        <DialogContent>
          {loading || loadingNewUser ? (
            <Box
              sx={{
                textAlign: "center",
                py: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
              <Typography variant="body1" sx={{ mt: 2 }}>
                {loadingNewUser ? "Creating User..." : "Loading Users..."}
              </Typography>
            </Box>
          ) : (
            <>
              <DialogContentText>
                Please fill out the form below to create a new user.
              </DialogContentText>

              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ mt: 2 }}
                noValidate
              >
                {/* Row 1 */}
                <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                  <TextField
                    margin="dense"
                    label="First Name"
                    variant="outlined"
                    value={newFirstName}
                    onChange={(e) => setNewFirstName(e.target.value)}
                    sx={{ width: "25%" }}
                  />
                  <TextField
                    margin="dense"
                    label="Last Name"
                    variant="outlined"
                    value={newLastName}
                    onChange={(e) => setNewLastName(e.target.value)}
                    sx={{ width: "25%" }}
                  />
                  <TextField
                    margin="dense"
                    label="Email"
                    placeholder="user@example.com"
                    variant="outlined"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    error={!!newEmail && !validateEmail(newEmail)}
                    sx={{ width: "25%" }}
                  />
                  <TextField
                    margin="dense"
                    label="Phone Number"
                    placeholder="+19172345678"
                    variant="outlined"
                    value={newPhoneNumber}
                    onChange={(e) => setNewPhoneNumber(e.target.value)}
                    error={
                      !!newPhoneNumber && !isValidPhoneNumber(newPhoneNumber)
                    }
                    sx={{ width: "25%" }}
                  />
                </Box>
                {/* Row 2 */}
                <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                  <TextField
                    margin="dense"
                    label="Age"
                    variant="outlined"
                    value={newAge}
                    onChange={(e) => setNewAge(e.target.value)}
                    sx={{ width: "10%" }}
                  />
                  <TextField
                    margin="dense"
                    label="Location"
                    variant="outlined"
                    value={newLocation}
                    onChange={(e) => setNewLocation(e.target.value)}
                    sx={{ width: "20%" }}
                  />
                  <TextField
                    margin="dense"
                    label="Country"
                    variant="outlined"
                    value={newCountry}
                    onChange={(e) => setNewCountry(e.target.value)}
                    sx={{ width: "20%" }}
                  />
                  <TextField
                    margin="dense"
                    label="Referral"
                    variant="outlined"
                    value={newReferral}
                    onChange={(e) => setNewReferral(e.target.value)}
                    sx={{ width: "25%" }}
                  />
                  <TextField
                    margin="dense"
                    label="Referral Relationship"
                    variant="outlined"
                    value={newReferralRelationship}
                    onChange={(e) => setNewReferralRelationship(e.target.value)}
                    sx={{ width: "25%" }}
                  />
                </Box>
                {/* Environment Row */}
                <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                  <FormControl
                    variant="outlined"
                    sx={{ minWidth: 150, marginTop: 1 }}
                  >
                    <InputLabel>Environment</InputLabel>
                    <Select
                      value={newEnvironment}
                      onChange={(e) => setNewEnvironment(e.target.value)}
                      label="Environment"
                    >
                      {Object.values(Environment).map((env) => (
                        <MenuItem key={env} value={env}>
                          {env}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* Only show "Is Friend" if a rolodex person exists */}
                  {rolodexPerson && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newIsFriend}
                          onChange={(e) => setNewIsFriend(e.target.checked)}
                        />
                      }
                      label="Is Friend"
                    />
                  )}
                </Box>
                {/* Only render Background Info if a rolodex person exists */}
                {rolodexPerson && (
                  <TextField
                    margin="dense"
                    label="Background Info"
                    fullWidth
                    variant="outlined"
                    value={newBackgroundInfo}
                    onChange={(e) => setNewBackgroundInfo(e.target.value)}
                    multiline
                    rows={4}
                    sx={{ mb: 2 }}
                  />
                )}
              </Box>
            </>
          )}
        </DialogContent>

        {/* Dialog actions (buttons) */}
        <Box
          sx={{
            display: "flex",
            gap: 2,
            mr: 2,
            mb: 2,
            justifyContent: "flex-end",
          }}
        >
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            {/* Disable the button or change text if loadingNewUser */}
            <Button
              onClick={handleSubmit}
              variant="contained"
              disabled={loadingNewUser || !isFormValid || loading}
            >
              {loadingNewUser ? "Creating..." : "Create"}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default UserCreateDialog;
