import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

interface ViewImageDialogProps {
  open: boolean;
  imageUrl: string;
  onClose: () => void;
}

const ViewImageDialog: React.FC<ViewImageDialogProps> = ({
  open,
  imageUrl,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Image Preview</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="preview"
            style={{ maxWidth: "100%", maxHeight: "80vh" }}
          />
        ) : (
          <div>No image available</div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewImageDialog;
