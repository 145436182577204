import { combineReducers } from "@reduxjs/toolkit";
import testReducer from "./testReducer";
import userReducer from "./userReducer";
import baseReducer from "./baseReducer";
import conversationsReducer from "./conversationsReducer";
import safetyIncidentsReducer from "./safetyIncidentsReducer";
import rolodexReducer from "./rolodexReducer";
import assetReducer from "./assetReducer";

const rootReducer = combineReducers({
  test: testReducer,
  users: userReducer,
  conversations: conversationsReducer,
  safetyIncidents: safetyIncidentsReducer,
  base: baseReducer,
  rolodex: rolodexReducer,
  assets: assetReducer,
});

export default rootReducer;
