import CryptoJS from "crypto-js";

interface ApiOptions {
  responseType?: "json" | "arraybuffer";
}

export const api = async (
  url_extension: string,
  method: string,
  data?: any,
  options?: ApiOptions
): Promise<any> => {
  const encryptedJwt = localStorage.getItem("jwt");
  let jwt: string | null = null;

  // Decrypt JWT if present
  if (encryptedJwt) {
    const bytes = CryptoJS.AES.decrypt(
      encryptedJwt,
      process.env.REACT_APP_CRYPTO_SECRET_KEY ?? ""
    );
    jwt = bytes.toString(CryptoJS.enc.Utf8);
  }

  // We'll build headers conditionally
  const headers = new Headers();
  if (jwt) {
    headers.set("Authorization", `Bearer ${jwt}`);
  }

  let body: BodyInit | undefined;

  // If data is a FormData object, let the browser set multipart/form-data automatically.
  if (data instanceof FormData) {
    body = data;
  } else if (data) {
    // Otherwise, assume JSON
    headers.set("Content-Type", "application/json");
    body = JSON.stringify(data);
  }
  // If no data, body remains undefined.

  const response = await fetch(
    (process.env.REACT_APP_BASE_BACKEND_URL ?? "") + url_extension,
    {
      method,
      headers,
      body,
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  if (options?.responseType === "arraybuffer") {
    return response.arrayBuffer();
  }

  return response.json();
};
