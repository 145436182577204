import { UserName } from "../redux/reducers/userReducer";

const BASE_DEMO_MEDIA_URL = "https://demo.persona-ai.ai/v1/media";
const BASE_STAGING_MEDIA_URL = "https://staging.persona-ai.ai/v1/media";

export const getFullNameFromUserName = (name?: UserName): string => {
  if (!name) return "";

  const { first_name = "", middle_name = "", last_name = "" } = name;
  return [first_name, middle_name, last_name].filter(Boolean).join(" ");
};

export const getFullPronunciationFromUserName = (name?: UserName): string => {
  if (!name) return "";

  const {
    first_name_phonetic = "",
    middle_name_phonetic = "",
    last_name_phonetic = "",
  } = name;
  return [first_name_phonetic, middle_name_phonetic, last_name_phonetic]
    .filter(Boolean)
    .join(" ");
};

export const getTargetId = (
  messageId: number,
  chatSessionId: string
): string => {
  return `msg:${chatSessionId}:${messageId.toString()}`;
};

export const getMediaBaseUrl = () => {
  // For example, if window.location.hostname is "localhost"
  // use the staging endpoint. Otherwise, use the demo endpoint.
  if (window.location.hostname === "localhost") {
    return BASE_STAGING_MEDIA_URL;
  }
  return BASE_DEMO_MEDIA_URL;
};
