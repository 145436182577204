import React, { useEffect, useMemo, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

interface MemoryModalProps {
  open: boolean;
  onClose: () => void;
  conversationId?: string;
}

const combineDocumentChunks = (
  chunks: Record<string, { text: string }> = {}
) => {
  return Object.values(chunks)
    .map((chunk) => chunk.text)
    .join("\n\n");
};

const MemoryModal: React.FC<MemoryModalProps> = ({
  open,
  onClose,
  conversationId,
}) => {
  const memoryData: any = useSelector((state: RootState) =>
    conversationId
      ? state.conversations.memoryData?.[conversationId]?.data
      : undefined
  );

  const memoryVersions: Record<string, string> = useSelector(
    (state: RootState) =>
      conversationId
        ? state.conversations.memoryData?.[conversationId]
            ?.memory_state_versions
        : {}
  );

  const loadingMemory = useSelector((state: RootState) =>
    conversationId
      ? state.conversations.loadingMemoryData?.[conversationId]
      : false
  );

  const [currentTab, setCurrentTab] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((e.ctrlKey || e.metaKey) && e.key === "f") {
      e.stopPropagation();
    }
  };

  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Focus the modal container so it can catch ctrl+f
    if (open && modalRef.current) {
      modalRef.current.focus();
    }
  }, [open]);

  // Safely extract data
  const investormemory = memoryData?.investormemory || {};
  const intake = memoryData?.intake || {};
  const background = memoryData?.background || {};
  const human = memoryData?.background?.human || {};
  const bot = memoryData?.background?.bot || {};
  const people = memoryData?.people || {};

  const investorInfo = investormemory?.investor_info || {};
  const intakeOverview = intake?.overview || {};
  const backgroundProgress = background?.progress?.records || {};
  const peopleEntries = people?.people || {};

  const docList = useMemo(() => {
    const documents = memoryData?.documents || {};
    const docEntries = documents?.documents || {};
    return Object.keys(docEntries).map((docKey) => {
      const doc = docEntries[docKey];
      const combinedText = combineDocumentChunks(doc.chunks);
      return {
        name: doc.document_name,
        text: combinedText,
      };
    });
  }, [memoryData?.documents]);

  // If no conversationId or loading, show spinner
  if (!conversationId || loadingMemory) {
    return (
      <Dialog
        ref={modalRef}
        onKeyDown={handleKeyDown}
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Conversation Memory
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" justifyContent="center" p={4}>
            <CircularProgress />
            <Typography sx={{ ml: 2 }}>Loading memory data...</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  // If memoryData is still not available after loading, show a message
  if (!memoryData) {
    return (
      <Dialog
        open={open}
        ref={modalRef}
        onKeyDown={handleKeyDown}
        onClose={onClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Conversation Memory
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>No memory data available.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  // Helper to render version info at the top of each tab content:
  const renderVersionInfo = (partitionKey: string) => {
    const version = memoryVersions?.[partitionKey] || "N/A";
    return (
      <Typography variant="body2" sx={{ mb: 2, fontStyle: "italic" }}>
        <strong>{partitionKey} Version:</strong> v{version}
      </Typography>
    );
  };

  return (
    <Dialog
      open={open}
      ref={modalRef}
      onKeyDown={handleKeyDown}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Conversation Memory
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {/* Collapsible Versions Panel */}
        {Object.keys(memoryVersions).length > 0 && (
          <Accordion sx={{ mb: 3 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">Show Memory Versions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {Object.entries(memoryVersions).map(([key, version]) => (
                <Typography variant="body2" key={key}>
                  <strong>{key}:</strong> v{version}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        )}

        {/* Tabs at the top to switch between sections */}
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="memory tabs"
          sx={{ mb: 2 }}
        >
          <Tab label="Investor Info" />
          <Tab label="Intake Overview" />
          <Tab label="Documents" />
          <Tab label="Session Summaries" />
          <Tab label="Human & Bot" />
          <Tab label="People" />
        </Tabs>

        {/* Investor Info Tab */}
        {currentTab === 0 && (
          <Box>
            {renderVersionInfo("investormemory")}
            <Typography variant="h6" gutterBottom>
              Investor Info
            </Typography>
            <Typography variant="body1">
              <strong>Background Info:</strong>{" "}
              {investorInfo.background_info || "N/A"}
            </Typography>
            <Typography variant="body1">
              <strong>Topic to Discuss:</strong>{" "}
              {investorInfo.investor_specific_topic_to_discuss || "N/A"}
            </Typography>
            <Typography variant="body1">
              <strong>Interrogation Q1:</strong>{" "}
              {investorInfo.interrogation_question_1 || "N/A"}
            </Typography>
            <Typography variant="body1">
              <strong>Is Investor Friend:</strong>{" "}
              {investorInfo.is_investor_friend ? "Yes" : "No"}
            </Typography>
            <Typography variant="body1">
              <strong>Fascinated By:</strong>{" "}
              {investorInfo.fascinated_by || "N/A"}
            </Typography>
          </Box>
        )}

        {/* Intake Overview Tab */}
        {currentTab === 1 && (
          <Box>
            {renderVersionInfo("intake")}
            <Typography variant="h6" gutterBottom>
              Intake Overview
            </Typography>
            <Typography variant="body1">
              <strong>Living Situation:</strong>{" "}
              {intakeOverview.living_situation?.summary || "N/A"}
            </Typography>
            <Typography variant="body1">
              <strong>Relationship:</strong>{" "}
              {intakeOverview.relationship?.summary || "N/A"}
            </Typography>
            <Typography variant="body1">
              <strong>Employment:</strong>{" "}
              {intakeOverview.employment?.summary || "N/A"}
            </Typography>
          </Box>
        )}

        {/* Documents Tab */}
        {currentTab === 2 && (
          <Box>
            {renderVersionInfo("documents")}
            <Typography variant="h6" gutterBottom>
              Documents
            </Typography>
            {docList.length === 0 && (
              <Typography>No documents found.</Typography>
            )}
            {docList.map((doc, index) => (
              <Accordion key={index} defaultExpanded={index === 0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">{doc.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
                    {doc.text}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        )}

        {/* Background (Progress) Tab */}
        {currentTab === 3 && (
          <Box>
            {renderVersionInfo("background")}
            <Typography variant="h6" gutterBottom>
              Session Summaries
            </Typography>
            {Object.entries(backgroundProgress).length === 0 && (
              <Typography>No session summary records found.</Typography>
            )}
            {Object.entries(backgroundProgress)
              .sort(
                ([, recordA]: [string, any], [, recordB]: [string, any]) => {
                  const dateA = new Date(recordA.updated_at).getTime();
                  const dateB = new Date(recordB.updated_at).getTime();
                  return dateA - dateB; // Ascending order
                }
              )
              .map(([key, record]: [string, any]) => (
                <Accordion key={key}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">
                      Session: {`...${key.slice(-10)}`}:
                      {format(
                        new Date(record.updated_at),
                        "yyyy-MM-dd HH:mm:ss"
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2">
                      <strong>Summary:</strong> {record.summary || "N/A"}
                    </Typography>
                    {record.trigger?.summary && (
                      <Typography variant="body2">
                        <strong>Trigger:</strong> {record.trigger.summary}
                      </Typography>
                    )}
                    {record.anxiety_level_start !== undefined && (
                      <Typography variant="body2">
                        <strong>Anxiety Start:</strong>{" "}
                        {record.anxiety_level_start}
                      </Typography>
                    )}
                    {record.anxiety_level_end !== undefined && (
                      <Typography variant="body2">
                        <strong>Anxiety End:</strong> {record.anxiety_level_end}
                      </Typography>
                    )}
                    {record.updated_at && (
                      <Typography variant="body2">
                        <strong>Updated At:</strong>{" "}
                        {format(
                          new Date(record.updated_at),
                          "yyyy-MM-dd HH:mm:ss"
                        )}
                      </Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
        )}

        {/* Human & Bot Tab */}
        {currentTab === 4 && (
          <Box>
            {renderVersionInfo("background")}
            <Typography variant="h6" gutterBottom>
              Human & Bot
            </Typography>
            <Typography variant="body1">
              <strong>Human Intro Summary:</strong>{" "}
              {human?.intro_summary || "N/A"}
            </Typography>
            <Typography variant="body1">
              <strong>Human Background Summary:</strong>{" "}
              {human?.background_summary || "N/A"}
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              <strong>Bot Background Summary:</strong>{" "}
              {bot?.background_summary || "N/A"}
            </Typography>
            <Typography variant="body1">
              <strong>Bot Style:</strong> {bot?.style || "N/A"}
            </Typography>
            <Typography variant="body1">
              <strong>Bot Description:</strong>{" "}
              {bot?.character_description || "N/A"}
            </Typography>
          </Box>
        )}

        {/* People Tab */}
        {currentTab === 5 && (
          <Box>
            {renderVersionInfo("people")}
            <Typography variant="h6" gutterBottom>
              People
            </Typography>

            {/* If there's an Owner record, display it first */}
            {people.owner && (
              <Box
                sx={{
                  mb: 2,
                  p: 2,
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Owner
                </Typography>
                <Typography variant="body1">
                  <strong>Name:</strong> {people.owner.name || "N/A"}
                </Typography>
                <Typography variant="body1">
                  <strong>Is Owner:</strong> {people.owner.owner ? "Yes" : "No"}
                </Typography>
                <Typography variant="body1">
                  <strong>Summary:</strong> {people.owner.summary || "N/A"}
                </Typography>
                {people.owner.last_discussion_time && (
                  <Typography variant="body1">
                    <strong>Last Discussion Time:</strong>{" "}
                    {format(
                      new Date(people.owner.last_discussion_time),
                      "yyyy-MM-dd HH:mm:ss"
                    )}
                  </Typography>
                )}

                {/* Owner Demographics in an Accordion */}
                <Accordion sx={{ mt: 2 }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">Demographics</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2">
                      <strong>Lives In:</strong>{" "}
                      {people.owner.demographics?.lives_in || "N/A"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Age:</strong>{" "}
                      {people.owner.demographics?.age || "N/A"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Originally From:</strong>{" "}
                      {people.owner.demographics?.originally_from || "N/A"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Employer:</strong>{" "}
                      {people.owner.demographics?.employer || "N/A"}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}

            {/* Now list individual people records */}
            {Object.entries(peopleEntries).length === 0 && (
              <Typography>No people records found.</Typography>
            )}
            {Object.entries(peopleEntries).map(
              ([personKey, person]: [string, any]) => (
                <Box
                  key={personKey}
                  sx={{
                    mb: 2,
                    p: 1,
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                >
                  <Typography variant="body1">
                    <strong>Name:</strong> {person.name || "N/A"}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Email:</strong> {person.email || "N/A"}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Name Pronunciation:</strong>{" "}
                    {person.name_pronunciation || "N/A"}
                  </Typography>
                  {person.relationship ? (
                    <Typography variant="body1">
                      <strong>Relationship:</strong>{" "}
                      {person.relationship || "N/A"}
                    </Typography>
                  ) : null}
                  <Typography variant="body1">
                    <strong>Knows Personally:</strong>{" "}
                    {person.knows_personally ? "Yes" : "No"}
                  </Typography>
                  {person.keyword_boost ? (
                    <Typography variant="body1">
                      <strong>Keyword Boost:</strong>{" "}
                      {person.keyword_boost || "N/A"}
                    </Typography>
                  ) : null}
                  {person.harmony?.reason ? (
                    <Typography variant="body1">
                      <strong>Harmony Reason:</strong>{" "}
                      {person.harmony?.reason || "N/A"}
                    </Typography>
                  ) : null}
                  {person.harmony?.value ? (
                    <Typography variant="body1">
                      <strong>Harmony Value:</strong>{" "}
                      {person.harmony?.value ?? "N/A"}
                    </Typography>
                  ) : null}
                  {person.last_interaction ? (
                    <Typography variant="body1">
                      <strong>Last Interaction:</strong>{" "}
                      {person.last_interaction
                        ? format(
                            new Date(person.last_interaction),
                            "yyyy-MM-dd HH:mm:ss"
                          )
                        : "N/A"}
                    </Typography>
                  ) : null}
                  <Typography variant="body1">
                    <strong>Updated At:</strong>{" "}
                    {person.updated_at
                      ? format(
                          new Date(person.updated_at),
                          "yyyy-MM-dd HH:mm:ss"
                        )
                      : "N/A"}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Parent IDs:</strong> {person.parent_ids || "N/A"}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Parent Names:</strong>{" "}
                    {person.parent_names || "N/A"}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Did Bot Meet:</strong>{" "}
                    {person.did_bot_meet ? "Yes" : "No"}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Summary:</strong> {person.summary || "N/A"}
                  </Typography>

                  {/* Demographics Accordion */}
                  <Accordion sx={{ mt: 1 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Demographics</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body2">
                        <strong>Lives In:</strong>{" "}
                        {person.demographics?.lives_in || "N/A"}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Age:</strong>{" "}
                        {person.demographics?.age || "N/A"}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Originally From:</strong>{" "}
                        {person.demographics?.originally_from || "N/A"}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Employer:</strong>{" "}
                        {person.demographics?.employer || "N/A"}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  {/* Bot Discussions Accordion */}
                  <Accordion sx={{ mt: 1 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Bot Discussions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {person.bot_discussions &&
                      Object.keys(person.bot_discussions).length > 0 ? (
                        Object.entries(person.bot_discussions).map(
                          ([discussionKey, discussion]: [string, any]) => (
                            <Box key={discussionKey} sx={{ mb: 2 }}>
                              <Typography variant="body2">
                                <strong>Chat Session ID:</strong>{" "}
                                {discussion.chat_session_id}
                              </Typography>
                              <Typography variant="body2">
                                <strong>Summary:</strong> {discussion.summary}
                              </Typography>
                              <Typography variant="body2">
                                <strong>Updated At:</strong>{" "}
                                {discussion.updated_at
                                  ? format(
                                      new Date(discussion.updated_at),
                                      "yyyy-MM-dd HH:mm:ss"
                                    )
                                  : ""}
                              </Typography>
                            </Box>
                          )
                        )
                      ) : (
                        <Typography>No bot discussions found.</Typography>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MemoryModal;
