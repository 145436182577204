import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { triggerMemoryForConversation } from "../../../../redux/actions/conversationsActions";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import { toast } from "react-toastify";
import { AppDispatch, RootState } from "../../../../redux/store";

interface TriggerMemoryButtonProps {
  conversationId: string;
  label?: string;
  icon?: React.ReactNode;
}

export const TriggerMemoryButton: React.FC<TriggerMemoryButtonProps> = ({
  conversationId,
  label = "Trigger Memory Build",
  icon = <BuildCircleIcon />, // default icon
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [rebuildAll, setRebuildAll] = useState(false);

  const isLoading = useSelector(
    (state: RootState) =>
      state?.conversations?.loadingMemoryTrigger?.[conversationId] === true
  );

  const handleOpenDialog = () => {
    setRebuildAll(false);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    setDialogOpen(false);

    dispatch(triggerMemoryForConversation({ conversationId, rebuildAll }))
      .unwrap()
      .then(() => {
        toast.success(
          `Memory triggered for conversation ${conversationId}. RebuildAll=${rebuildAll}`
        );
      })
      .catch((err: any) => {
        toast.error(`Failed to trigger memory. ${err?.message || err}`);
      });
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenDialog}
        startIcon={icon}
        disabled={isLoading}
      >
        {isLoading ? "Triggering..." : label}
      </Button>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Trigger Memory Build</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" sx={{ mb: 2 }}>
            You are about to trigger a memory build for conversation
            <strong> {conversationId}</strong>.
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={rebuildAll}
                onChange={(e) => setRebuildAll(e.target.checked)}
                color="primary"
              />
            }
            label="Rebuild All Memory"
          />
          <Typography variant="caption" display="block">
            Check to force a full rebuild of ALL MEMORY for this conversation.
            THIS IS DANGEROUS
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Trigger
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
