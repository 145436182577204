// src/components/AssetView/asset-content/SortableBulletList.tsx

import React, { useCallback } from "react";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import BulletItemEditor, { BulletItem } from "./BulletItemEditor";
import { Box, IconButton, Typography } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

interface SortableBulletListProps {
  bullets: BulletItem[];
  disabled?: boolean;
  onChange: (newBullets: BulletItem[]) => void;
  onRemove?: (index: number) => void;
  onAdd?: () => void;
}

const SortableBulletList: React.FC<SortableBulletListProps> = ({
  bullets,
  disabled,
  onChange,
  onRemove,
  onAdd,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5, // Drag starts after 5px movement
      },
    })
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (!over) return;
      if (active.id !== over.id) {
        const oldIndex = bullets.findIndex((b) => b.id === active.id);
        const newIndex = bullets.findIndex((b) => b.id === over.id);
        const reordered = arrayMove(bullets, oldIndex, newIndex);
        onChange(reordered);
      }
    },
    [bullets, onChange]
  );

  if (!bullets) {
    return null;
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={bullets.map((b) => b.id)}
        strategy={verticalListSortingStrategy}
      >
        {bullets.map((bullet, index) => (
          <SortableBulletItem
            key={`${bullet.id}-${index}`}
            bullet={bullet}
            index={index}
            disabled={!!disabled}
            onChange={(updated) => {
              const newList = [...bullets];
              newList[index] = updated;
              onChange(newList);
            }}
            onRemove={onRemove ? () => onRemove(index) : undefined}
          />
        ))}
      </SortableContext>
      {onAdd && !disabled && (
        <Typography
          variant="body2"
          sx={{ textDecoration: "underline", cursor: "pointer", mt: 1 }}
          onClick={onAdd}
        >
          + Add Bullet
        </Typography>
      )}
    </DndContext>
  );
};

interface SortableBulletItemProps {
  bullet: BulletItem;
  index: number;
  disabled: boolean;
  onChange: (b: BulletItem) => void;
  onRemove?: () => void;
}

function SortableBulletItem({
  bullet,
  index,
  disabled,
  onChange,
  onRemove,
}: SortableBulletItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: bullet.id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: disabled ? "default" : "grab",
    willChange: "transform",
  };

  return (
    <Box ref={setNodeRef} style={style}>
      <BulletItemEditor
        item={bullet}
        disabled={disabled}
        onChange={onChange}
        onRemove={onRemove}
        dragHandle={
          !disabled && (
            <IconButton size="small" {...attributes} {...listeners}>
              <DragIndicatorIcon fontSize="small" />
            </IconButton>
          )
        }
      />
    </Box>
  );
}

export default SortableBulletList;
