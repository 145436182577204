import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../api_middleware";
import { Asset } from "../reducers/assetReducer";

export const fetchAssets = createAsyncThunk("assets/fetchAssets", async () => {
  const response = await api("v1/admin/assets/", "GET");
  return response;
});

interface FetchAssetParams {
  namespace: string;
  assetId: string;
}
export const fetchAsset = createAsyncThunk(
  "assets/fetchAsset",
  async (params: FetchAssetParams) => {
    const { namespace, assetId } = params;
    const response = await api(
      `v1/admin/assets/${namespace}/${assetId}`,
      "GET"
    );
    return response;
  }
);

interface AssetContentItem {
  text: string;
  image_url?: string;
}
interface AssetContentRequest {
  type: string; // e.g. "story/faq_entry"
  subject?: string;
  story_items: AssetContentItem[];
  faq_entry?: string;
  faq_topic?: string;
}
interface CreateAssetParams {
  namespace: string;
  id: string;
  type: string;
  contents: AssetContentRequest[];
}
export const createAsset = createAsyncThunk(
  "assets/createAsset",
  async (params: CreateAssetParams) => {
    const response = await api("v1/admin/assets/", "POST", params);
    return response;
  }
);

export const updateAsset = createAsyncThunk(
  "assets/updateAsset",
  async (asset: Asset) => {
    const response = await api(`v1/admin/assets/`, "POST", asset);
    return response;
  }
);

interface DeleteAssetParams {
  namespace: string;
  assetId: string;
}
export const deleteAsset = createAsyncThunk(
  "assets/deleteAsset",
  async (params: DeleteAssetParams) => {
    const { namespace, assetId } = params;
    const response = await api(
      `v1/admin/assets/${namespace}/${assetId}`,
      "DELETE"
    );
    return response;
  }
);

interface PublishAssetParams {
  namespace: string;
  assetId: string;
}
export const publishAsset = createAsyncThunk(
  "assets/publishAsset",
  async (params: PublishAssetParams) => {
    const { namespace, assetId } = params;
    const response = await api(
      `v1/admin/assets/${namespace}/${assetId}/publish`,
      "POST"
    );
    return response;
  }
);

interface MakeStableAssetParams {
  namespace: string;
  assetId: string;
}
export const makeStableAsset = createAsyncThunk(
  "assets/makeStableAsset",
  async (params: MakeStableAssetParams) => {
    const { namespace, assetId } = params;
    const response = await api(
      `v1/admin/assets/${namespace}/${assetId}/make_stable`,
      "POST"
    );
    return response;
  }
);

interface UploadPublicAssetParams {
  folder: string;
  file: File;
}
export const uploadPublicAsset = createAsyncThunk(
  "assets/uploadPublicAsset",
  async (params: UploadPublicAssetParams) => {
    const formData = new FormData();
    formData.append("folder", params.folder);
    formData.append("file", params.file);

    const response = await api(
      "v1/admin/upload_public_asset",
      "POST",
      formData
    );
    return response as { url: string };
  }
);
