import React, { useState, useEffect } from "react";
import { useStytch, useStytchUser } from "@stytch/react";
import "./Login.css";
import { useNavigate, useLocation } from "react-router-dom";

export const Login = () => {
  const stytchClient = useStytch();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageVisible, setMessageVisible] = useState(false);
  const [messageHeight, setMessageHeight] = useState(0);
  const queryParams = new URLSearchParams(window.location.search);

  const sendEmailMagicLink = async () => {
    setLoading(true);
    try {
      const loginRedirectUrl = new URL(
        process.env.REACT_APP_LOGIN_REDIRECT_URL ?? ""
      );
      const redirect = queryParams.get("redirect");
      if (redirect) {
        loginRedirectUrl.searchParams.append("redirect", redirect);
      }
      await stytchClient.magicLinks.email.send(email, {
        login_magic_link_url: loginRedirectUrl.toString(),
        login_expiration_minutes: parseInt(
          process.env.REACT_APP_LOGIN_LINK_MIN_EXPIRY ?? "5"
        ),
        login_template_id: process.env.REACT_APP_LOGIN_TEMPLATE_ID,
        signup_template_id: process.env.REACT_APP_SIGNUP_TEMPLATE_ID,

      });
      setMessage("Login link sent! Check your email.");
      setIsError(false);
    } catch (error) {
      setMessage("Failed to send login link. Please try again.");
      setIsError(true);
    }
    setMessageVisible(true);
    setLoading(false);
  };

  const handleClick = () => {
    setMessageVisible(false);
    sendEmailMagicLink();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };

  useEffect(() => {
    if (message) {
      setMessageHeight(50); // Adjust based on the expected height of the message
    } else {
      setMessageHeight(0);
    }
  }, [message]);

  useEffect(() => {
    if (messageVisible) {
      const timeout = setTimeout(() => {
        setMessageHeight(50); // Adjust based on the expected height of the message
      }, 300);
      return () => clearTimeout(timeout);
    }
  }, [messageVisible]);

  const { user } = useStytchUser();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user) {
      const { from } = location.state || { from: { pathname: "/dashboard" } };
      navigate(from);
    }
  }, [user, navigate, location]);

  return (
    <div className="login-container">
      <h1 className="login-heading">BetterNow Admin</h1>
      <div className="login-form">
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={handleKeyDown}
          className="login-input"
          required
        />
        <button
          type="button"
          className="login-button"
          onClick={handleClick}
          disabled={loading}
        >
          Get Login Link Via Email
          {loading && <div className="login-loading"></div>}
        </button>
        <div
          className="login-message-container"
          style={{ height: messageHeight }}
        >
          {messageVisible && (
            <p
              className={`login-message ${isError ? "error" : "success"} ${messageVisible ? "visible" : ""
                }`}
            >
              {message}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
