// src/components/UserView.tsx
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { RootState, AppDispatch } from "../../../redux/store";
import { updateUserStatus } from "../../../redux/actions/userActions";
import { updateUser } from "../../../redux/actions/userActions";
import {
  getFullNameFromUserName,
  getFullPronunciationFromUserName,
} from "../../../utils/render";
import { User, UserStatus } from "../../../redux/reducers/userReducer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SafetyIncidentsTab from "../SafetyIncidentsTab";
import ConversationsTab from "../ConversationsTab";

const UserView: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const users = useSelector((state: RootState) => state.users.users);

  const user = useSelector((state: RootState) =>
    state.users.users.find((u: User) => u.id === userId)
  );

  useEffect(() => {
    // If a toast message was passed via navigation state, show it.
    if (location.state && (location.state as any).toastMessage) {
      toast.success((location.state as any).toastMessage);
      // Clear the state so the toast isn’t shown again.
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate]);

  // If no users loaded, navigate to the list or attach ?user= param
  useEffect(() => {
    if (users.length === 0) {
      if (userId) {
        navigate(`/dashboard/users?user=${userId}`);
      } else {
        navigate(`/dashboard/users`);
      }
    }
  }, [users, userId, navigate]);

  const [open, setOpen] = useState(false);
  const [deactivationReason, setDeactivationReason] = useState("");

  // NEW: States for “Edit Pronunciation” dialog
  const [pronunciationDialogOpen, setPronunciationDialogOpen] = useState(false);
  const [firstNamePhonetic, setFirstNamePhonetic] = useState("");
  const [middleNamePhonetic, setMiddleNamePhonetic] = useState("");
  const [lastNamePhonetic, setLastNamePhonetic] = useState("");

  const handleToggleActive = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDeactivationReason("");
  };

  const handleUserStatusUpdate = (status: UserStatus) => {
    dispatch(
      updateUserStatus({
        id: user?.id ?? "",
        status: status,
        deactivation_reason: deactivationReason,
        user: user,
      })
    )
      .unwrap()
      .then(() => {
        toast.success(`User status changed to ${status}`);
      })
      .catch((error) => {
        toast.error("Failed to change user status");
      });
    setOpen(false);
  };

  const handlePronunciationUpdate = () => {
    if (!user) return;
    const updatedUser: User = {
      ...user,
      name: {
        ...user.name,
        first_name_phonetic: firstNamePhonetic,
        middle_name_phonetic: middleNamePhonetic,
        last_name_phonetic: lastNamePhonetic,
      },
    };

    dispatch(updateUser(updatedUser))
      .unwrap()
      .then(() => {
        toast.success("Pronunciation updated successfully");
        setPronunciationDialogOpen(false);
      })
      .catch(() => {
        toast.error("Failed to update pronunciation");
      });
  };

  const {
    user: adminUser,
    loading: userLoading,
    error: userError,
  } = useSelector((state: RootState) => state.base);

  const canViewConversations = adminUser?.scopes?.includes(
    "conversationview:view"
  );
  const canViewSafetyIncidents = adminUser?.scopes?.includes(
    "safetyincidentsview:view"
  );

  if (!user || userLoading || userError) {
    return <div>User not found</div>;
  }

  return (
    <Box sx={{ p: 4 }}>
      <ToastContainer position="top-center" autoClose={3000} />
      <Typography variant="h5" gutterBottom>
        User Details
      </Typography>

      <TextField
        label="User Name"
        value={getFullNameFromUserName(user.name)}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />

      <Box display="flex" alignItems="center">
        <TextField
          label="Pronunciation"
          value={getFullPronunciationFromUserName(user.name)}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
        />
        <Button
          variant="outlined"
          size="small"
          sx={{ ml: 2, mt: 2 }}
          onClick={() => {
            setFirstNamePhonetic(user.name?.first_name_phonetic || "");
            setMiddleNamePhonetic(user.name?.middle_name_phonetic || "");
            setLastNamePhonetic(user.name?.last_name_phonetic || "");
            setPronunciationDialogOpen(true);
          }}
        >
          Edit
        </Button>
      </Box>

      <TextField
        label="User ID"
        value={user.id}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Stytch User ID"
        value={user.stytch_user_id}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Phone Number"
        value={user.phone_number}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Email"
        value={user.email}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Status"
        value={user.status}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Environment"
        value={user.environment}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />

      {user.status !== UserStatus.ACTIVE && (
        <TextField
          color="warning"
          label="Deactivation Reason"
          value={user.deactivation_reason}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
        />
      )}
      <FormControlLabel
        control={
          <Checkbox
            icon={<CancelIcon sx={{ color: "red" }} />}
            checkedIcon={<CheckCircleIcon sx={{ color: "green" }} />}
            disabled
          />
        }
        label="Is Admin"
        checked={user.is_admin}
      />
      <FormControlLabel
        control={
          <Checkbox
            icon={<CancelIcon sx={{ color: "red" }} />}
            checkedIcon={<CheckCircleIcon sx={{ color: "green" }} />}
            disabled
          />
        }
        label="Phone Number Verified"
        checked={user.phone_number_verified}
      />
      <FormControlLabel
        control={
          <Checkbox
            icon={<CancelIcon sx={{ color: "red" }} />}
            checkedIcon={<CheckCircleIcon sx={{ color: "green" }} />}
            disabled
          />
        }
        label="Email Verified"
        checked={user.email_verified}
      />
      {canViewConversations ? (
        <ConversationsTab canReadConversation={true} userId={user.id} />
      ) : null}
      {canViewSafetyIncidents ? <SafetyIncidentsTab userId={user.id} /> : null}

      <Box display="flex" alignItems="center" marginTop={"16px"}>
        <Button variant="contained" color="error" onClick={handleToggleActive}>
          Change User Status
        </Button>
        <Button
          variant="contained"
          color="warning"
          onClick={() => navigate("/dashboard/users")}
          style={{ marginLeft: "8px" }}
        >
          Back
        </Button>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Change User Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide an optional reason for
            suspending/banning/reactivating the user.
          </DialogContentText>
          <TextField
            autoFocus={user.status === UserStatus.ACTIVE}
            margin="dense"
            label="Reason for Suspension/Ban"
            type="text"
            fullWidth
            variant="standard"
            value={deactivationReason}
            onChange={(e) => setDeactivationReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {user.status !== UserStatus.ONBOARDING && (
            <Button
              onClick={() => handleUserStatusUpdate(UserStatus.ONBOARDING)}
              color="info"
            >
              Re-Onboard
            </Button>
          )}
          {user.status !== UserStatus.SUSPENDED && (
            <Button
              onClick={() => handleUserStatusUpdate(UserStatus.SUSPENDED)}
              color="warning"
            >
              Suspend
            </Button>
          )}
          {user.status !== UserStatus.BANNED && (
            <Button
              onClick={() => handleUserStatusUpdate(UserStatus.BANNED)}
              color="error"
            >
              Ban
            </Button>
          )}
          {user.status !== UserStatus.ACTIVE && (
            <Button
              onClick={() => handleUserStatusUpdate(UserStatus.ACTIVE)}
              color="success"
            >
              Reactivate
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* NEW: Dialog for editing pronounciation fields */}
      <Dialog
        open={pronunciationDialogOpen}
        onClose={() => setPronunciationDialogOpen(false)}
      >
        <DialogTitle>Edit Pronunciation</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="First Name Phonetic"
            type="text"
            fullWidth
            variant="standard"
            value={firstNamePhonetic}
            onChange={(e) => setFirstNamePhonetic(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Middle Name Phonetic"
            type="text"
            fullWidth
            variant="standard"
            value={middleNamePhonetic}
            onChange={(e) => setMiddleNamePhonetic(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Last Name Phonetic"
            type="text"
            fullWidth
            variant="standard"
            value={lastNamePhonetic}
            onChange={(e) => setLastNamePhonetic(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPronunciationDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handlePronunciationUpdate} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
      {/* END: Pronunciation Edit Dialog */}
    </Box>
  );
};

export default UserView;
