import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../api_middleware";
import { User, UserStatus } from "../reducers/userReducer";
import { Conversation } from "../reducers/conversationsReducer";
import { conversationAdded } from "../reducers/conversationsReducer";

export const fetchUsers = createAsyncThunk("users/fetchUsers", async () => {
  const response = await api("v1/admin/users", "GET");
  return response;
});

interface UpdateUserStatusParams {
  id: string;
  status: UserStatus;
  deactivation_reason?: string;
  user?: User;
}

export const updateUserStatus = createAsyncThunk(
  "users/updateUserStatus",
  async ({ id, status, deactivation_reason, user }: UpdateUserStatusParams) => {
    const response = await api(`v1/admin/users/${id}`, "PUT", {
      ...user,
      status,
      deactivation_reason,
    });
    return response;
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (updatedUser: User) => {
    const response = await api(
      `v1/admin/users/${updatedUser.id}`,
      "PUT",
      updatedUser
    );
    return response;
  }
);

export interface AdminFormUserData {
  first_name: string;
  environment: string;
  is_friend?: boolean;
  last_name?: string;
  email?: string;
  phone_number?: string;
  location?: string;
  country?: string;
  age?: number;
  referral?: string;
  referral_relationship?: string;
  background?: string;
}

export interface CreateUserRequest {
  user: AdminFormUserData;
  create_conversation?: boolean;
}
export interface CreateConversationRequest {
  user: User;
  context: AdminConversationFormData;
}

export interface CreateUserResponse {
  user: User;
  conversation?: Conversation;
}

export interface AdminConversationFormData {
  environment: string;
  background?: string;
  is_friend?: boolean;
}

export const createUser = createAsyncThunk(
  "users/createUser",
  async (request: CreateUserRequest, { dispatch }) => {
    const response = await api("v1/admin/create_user", "POST", request);
    const data = response as CreateUserResponse;

    if (data.conversation) {
      dispatch(conversationAdded(data.conversation));
    }

    return data.user as User;
  }
);
