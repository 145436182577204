import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../api_middleware";
import { v4 as uuidv4 } from "uuid";
import { getTargetId } from "../../utils/render";
import { RootState } from "../store";
import { Conversation, LogSchema } from "../reducers/conversationsReducer";
import { CreateConversationRequest } from "./userActions";

const TWENTY_FIVE_MINUTES = 25 * 60 * 1000;

export const fetchConversations = createAsyncThunk(
  "conversations/fetchConversations",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const now = Date.now();
    if (
      now - state.conversations.lastFetchTime < TWENTY_FIVE_MINUTES &&
      state.conversations.conversations.length > 0
    ) {
      // Data is fresh, return existing data from cache
      return { data: state.conversations.conversations, fromCache: true };
    }
    const response = await api("v1/admin/conversations", "GET");
    return { data: response, fromCache: false };
  }
);

export const fetchTranscripts = createAsyncThunk(
  "conversations/fetchTranscripts",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const now = Date.now();
    if (
      now - state.conversations.lastTranscriptFetchTime < TWENTY_FIVE_MINUTES &&
      state.conversations.transcripts.length > 0
    ) {
      // Data is fresh, return existing data from cache
      return { data: state.conversations.transcripts, fromCache: true };
    }
    const response = await api("v1/admin/transcripts", "GET");
    return { data: response, fromCache: false };
  }
);

export const fetchMemoryData = createAsyncThunk(
  "conversations/fetchMemoryData",
  async (conversationId: string) => {
    const response = await api(`v1/admin/memory/${conversationId}`, "GET");
    return response;
  }
);

export const fetchChatSessions = createAsyncThunk(
  "conversations/fetchChatSessions",
  async (conversationId: string) => {
    const response = await api(
      `v1/admin/chat-sessions/${conversationId}`,
      "GET"
    );
    return response;
  }
);

export const fetchAnonymizedChatSessions = createAsyncThunk(
  "conversations/fetchAnonymizedChatSessions",
  async (conversationId: string) => {
    const response = await api(
      `v1/admin/anonymized-chat-sessions/${conversationId}`,
      "GET"
    );
    return response;
  }
);

// New Actions for Comments

export const fetchComments = createAsyncThunk(
  "conversations/fetchComments",
  async (conversationId: string) => {
    const response = await api(
      `v1/admin/annotations/${conversationId}/comments`,
      "GET"
    );
    return response;
  }
);

export const addComment = createAsyncThunk(
  "conversations/addComment",
  async (payload: {
    conversationId: string;
    comment: string;
    tags: string[];
    targetId?: string;
    threadId?: string;
  }) => {
    // Generate a unique UUID if threadId is not provided
    const threadId = payload.threadId || uuidv4();

    const response = await api(
      `v1/admin/annotations/${payload.conversationId}/comment`,
      "POST",
      {
        target_id: payload.targetId,
        comment: payload.comment,
        thread_id: threadId,
        tags: payload.tags,
      }
    );
    return response;
  }
);

export const editComment = createAsyncThunk(
  "conversations/editComment",
  async (payload: {
    conversationId: string;
    commentId: string;
    comment: string;
    tags: string[];
  }) => {
    const response = await api(
      `v1/admin/annotations/${payload.conversationId}/comment/${payload.commentId}`,
      "PUT",
      {
        comment: payload.comment,
        tags: payload.tags,
      }
    );
    return response;
  }
);

export const deleteComment = createAsyncThunk(
  "conversations/deleteComment",
  async (payload: { conversationId: string; commentId: string }) => {
    await api(
      `v1/admin/annotations/${payload.conversationId}/comment/${payload.commentId}`,
      "DELETE"
    );
    return payload.commentId;
  }
);

export const setConversationShadowBanned = createAsyncThunk(
  "users/setConversationShadowBanned",
  async (payload: { conversationId: string; newValue: boolean }) => {
    await api("v1/admin/conversations/shadow_ban", "POST", {
      conversation_id: payload.conversationId,
      new_value: payload.newValue,
    });
    return {
      conversationId: payload.conversationId,
      newValue: payload.newValue,
    };
  }
);

export const fetchStructuredLogs = createAsyncThunk(
  "conversations/fetchStructuredLogs",
  async (payload: {
    conversationId: string;
    chatSessionId: string;
    clientMsgUid?: string;
    startMsgId: number;
    actionId?: string;
    requestedEvents: Array<{ event: string; orderings: string[] }>;
    targetSchema?: LogSchema;
  }) => {
    const schema = payload.targetSchema ?? LogSchema.EVENTSPROD;

    const response = await api("v1/admin/structured_logs", "POST", {
      target: {
        conversation_id: payload.conversationId,
        chat_session_id: payload.chatSessionId,
        client_msg_uid: payload.clientMsgUid ?? "",
        action_id: payload.actionId ?? "",
      },
      requested_events: payload.requestedEvents,
      target_schema: schema.valueOf(),
    });

    return {
      data: response,
      targetId: getTargetId(payload.startMsgId, payload.chatSessionId),
      usedSchema: schema,
    };
  }
);
interface GenerateChatSessionAudioPayload {
  conversationId: string;
  chatSessionId: string;
}

export const generateChatSessionAudio = createAsyncThunk(
  "conversations/generateChatSessionAudio",
  async (payload: GenerateChatSessionAudioPayload) => {
    const { conversationId, chatSessionId } = payload;

    // First request transcript generation
    const generationResponse = await api(
      "v1/admin/generate_chat_session_audio_transcript",
      "POST",
      {
        conversation_id: conversationId,
        chat_session_id: chatSessionId,
      }
    );

    const blobPath = generationResponse.stored_audio.path;
    const container = generationResponse.stored_audio.container;

    // Poll for availability
    const start = Date.now();
    const timeout = 60000;
    let downloaded: ArrayBuffer | null = null;

    while (Date.now() - start < timeout) {
      try {
        // Attempt direct download
        const downloadResponse: ArrayBuffer = await api(
          "v1/admin/download_media",
          "POST",
          {
            blob_path: blobPath,
            container: container,
            wait_for_generation_timeout_sec: 60,
          },
          { responseType: "arraybuffer" }
        );

        downloaded = downloadResponse;
        break;
      } catch (e) {
        // If 404 or not ready, wait and retry
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    }

    if (!downloaded) {
      throw new Error("Audio transcript not available in time");
    }

    // Convert ArrayBuffer to a Blob URL
    const blob = new Blob([downloaded], { type: "audio/wav" });
    const audioUrl = URL.createObjectURL(blob);

    return {
      conversationId,
      chatSessionId,
      audioUrl,
    };
  }
);

interface TriggerMemoryPayload {
  conversationId: string;
  rebuildAll?: boolean;
}

/**
 * Trigger or rebuild memory for a given conversation.
 */
export const triggerMemoryForConversation = createAsyncThunk(
  "conversations/triggerMemoryForConversation",
  async ({ conversationId, rebuildAll = false }: TriggerMemoryPayload) => {
    const response = await api(
      `v1/admin/conversations/${conversationId}/trigger_memory`,
      "POST",
      {
        rebuild_all: rebuildAll,
      }
    );
    return response;
  }
);

export const createConversation = createAsyncThunk(
  "conversations/createConversation",
  async (request: CreateConversationRequest) => {
    const response = await api("v1/admin/create_conversation", "POST", request);
    return response as Conversation;
  }
);