import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

interface ViewMarkdownDialogProps {
  open: boolean;
  markdown: string;
  onClose: () => void;
}

const ViewMarkdownDialog: React.FC<ViewMarkdownDialogProps> = ({
  open,
  markdown,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Markdown Preview</DialogTitle>
      <DialogContent>
        <Typography
          variant="body2"
          component="pre"
          sx={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            backgroundColor: "#f5f5f5",
            padding: 2,
            borderRadius: 1,
          }}
        >
          {markdown}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewMarkdownDialog;
