import React, { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Chip,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateUserDialog from "../users/CreateUserDialog";
import { RootState, AppDispatch } from "../../../redux/store";
import { RolodexPerson } from "../../../redux/reducers/rolodexReducer";
import {
  publishRolodexPerson,
  updateRolodexPerson,
} from "../../../redux/actions/rolodexActions";
import { fetchConversations } from "../../../redux/actions/conversationsActions";
import { format } from "date-fns";

const formatDate = (dateString: string | undefined) => {
  if (!dateString) return "N/A"; // Handle empty or undefined dates
  const date = new Date(dateString);
  return format(date, "MMMM d, yyyy h:mm aaaa 'PST'");
};

const RolodexView: React.FC = () => {
  const { personId } = useParams<{ personId: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  // Redux slices
  const {
    rolodex,
    loading: rolodexLoading,
    error: rolodexError,
  } = useSelector((state: RootState) => state.rolodex);
  const { conversations, loading: convoLoading } = useSelector(
    (state: RootState) => state.conversations
  );

  // The current person, found by ID
  const person = rolodex.find((p: RolodexPerson) => p.id === personId);

  // UI state
  const [editMode, setEditMode] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [publishDialogOpen, setPublishDialogOpen] = useState(false);

  // Fields for editing
  const [firstName, setFirstName] = useState("");
  const [firstNamePhonetic, setFirstNamePhonetic] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [middleNamePhonetic, setMiddleNamePhonetic] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNamePhonetic, setLastNamePhonetic] = useState("");
  const [summary, setSummary] = useState("");
  const [selectedConversations, setSelectedConversations] = useState<string[]>(
    []
  );
  const [boostedThreshold, setBoostedThreshold] = useState("0");
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);

  const adminUser = useSelector((state: RootState) => state.base.user);
  const canCreateUsers = adminUser?.scopes?.includes("user:write");

  // If our Rolodex is empty, but we have a personId, navigate to list w/param
  useEffect(() => {
    if (rolodex.length === 0) {
      if (personId) {
        navigate(`/dashboard/rolodex?rolodex_id=${personId}`);
      } else {
        navigate(`/dashboard/rolodex`);
      }
    }
  }, [rolodex, personId, navigate]);

  const handleEmailChange = (newVal: string) => {
    setEmail(newVal);
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailValid(!newVal || pattern.test(newVal));
  };

  // When the `person` changes, initialize local states
  useEffect(() => {
    if (person) {
      const f = person.data?.name.first_name || "";
      const m = person.data?.name.middle_name || "";
      const l = person.data?.name.last_name || "";
      const fp = person.data?.name.first_name_phonetic || "";
      const mp = person.data?.name.middle_name_phonetic || "";
      const lp = person.data?.name.last_name_phonetic || "";
      setFirstName(f);
      setMiddleName(m);
      setLastName(l);
      setFirstNamePhonetic(fp);
      setMiddleNamePhonetic(mp);
      setLastNamePhonetic(lp);
      setSummary(person.data.summary || "");
      setEmail(person.data.email || "");

      const thresholdValue =
        person.data.boosted_threshold == null
          ? "0"
          : String(person.data.boosted_threshold);
      setBoostedThreshold(thresholdValue);
      setSelectedConversations([...person.conversation_ids]);

      if (person.data.email) {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmailValid(pattern.test(person.data.email));
      } else {
        setEmailValid(true);
      }
    }
  }, [person]);

  // Check for duplicate email and duplicate full name
  const existingRolodexWithEmail = useMemo(() => {
    if (!email.trim()) return false;
    return rolodex.some(
      (p) =>
        p.data.email?.toLowerCase() === email.trim().toLowerCase() &&
        p.id !== personId
    );
  }, [rolodex, email, personId]);

  const existingRolodexWithName = useMemo(() => {
    if (!firstName.trim() || !lastName.trim()) return false;
    return rolodex.some(
      (p) =>
        p.data.name.first_name?.toLowerCase() ===
          firstName.trim().toLowerCase() &&
        p.data.name.last_name?.toLowerCase() ===
          lastName.trim().toLowerCase() &&
        p.id !== personId
    );
  }, [rolodex, firstName, lastName, personId]);

  // Toggle edit mode
  const handleToggleEdit = () => {
    if (!editMode) {
      // We are about to enter edit mode; fetch conversations if they're empty
      if (conversations.length === 0) {
        dispatch(fetchConversations());
      }
    }
    setEditMode((prev) => !prev);
  };

  // Save changes confirm
  const handleOpenConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };
  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  // Publish confirm
  const handleOpenPublishDialog = () => {
    setPublishDialogOpen(true);
  };
  const handleClosePublishDialog = () => {
    setPublishDialogOpen(false);
  };

  const handleConfirmPublish = () => {
    if (!personId) return;
    setPublishDialogOpen(false);
    dispatch(publishRolodexPerson({ id: personId }))
      .unwrap()
      .then(() => {
        toast.success("Rolodex person published successfully!");
      })
      .catch(() => {
        toast.error("Failed to publish rolodex person.");
      });
  };

  // Save changes
  const handleSaveChanges = () => {
    if (!personId || !person) return;
    if (!emailValid) {
      toast.error("Cannot save: Invalid email address");
      return;
    }

    // Duplicate email check
    if (existingRolodexWithEmail) {
      toast.error(
        "A Rolodex person with that email already exists. Cannot update with this email again."
      );
      return;
    }

    // Duplicate name check
    if (existingRolodexWithName) {
      if (
        !window.confirm(
          "A Rolodex person with the exact same full name exists. Do you still want to proceed?"
        )
      ) {
        return;
      }
    }

    setConfirmDialogOpen(false);

    // Build the updates
    const updates: Partial<RolodexPerson> = {
      conversation_ids: [...selectedConversations],
      data: {
        ...person.data,
        name: {
          ...person.data.name,
          first_name: firstName,
          first_name_phonetic: firstNamePhonetic,
          middle_name: middleName,
          middle_name_phonetic: middleNamePhonetic,
          last_name: lastName,
          last_name_phonetic: lastNamePhonetic,
        },
        summary,
        boosted_threshold: Number(boostedThreshold),
        email,
      },
    };

    dispatch(updateRolodexPerson({ id: personId, updates }))
      .unwrap()
      .then(() => {
        toast.success("Rolodex person updated successfully!");
        setEditMode(false);
      })
      .catch(() => {
        toast.error("Failed to update rolodex person.");
      });
  };

  // If still loading or there's an error
  if (rolodexLoading) return <CircularProgress />;
  if (rolodexError) return <div>Error: {rolodexError}</div>;
  if (!person) return <div>No Rolodex person found with ID: {personId}</div>;

  // Also check if conversation fetch is in progress (only relevant if edit mode)
  const showConversationLoader =
    editMode && convoLoading && conversations.length === 0;

  // Determine background based on editMode:
  //   - If editMode => transparent
  //   - If read-only => "rgba(0,0,0,0.05)"
  const getBackgroundColor = (edit: boolean) =>
    edit ? "transparent" : "rgba(0,0,0,0.05)";

  // Check if publish button should be disabled
  const publishDisabled = person.version === person.published_version;

  // Published conversation IDs
  const publishedConvIds = person?.published_conversation_ids || [];

  // Called whenever the user changes the set of conversation IDs
  const handleConversationsChange = (_: any, newValue: string[]) => {
    // Force published IDs to remain in the list
    const finalValue = [
      ...publishedConvIds,
      ...newValue.filter((id) => !publishedConvIds.includes(id)),
    ];
    setSelectedConversations(finalValue);
  };

  return (
    <Box sx={{ p: 4 }}>
      <ToastContainer position="top-center" autoClose={3000} />
      <Typography variant="h5" gutterBottom>
        Rolodex Person Details
      </Typography>

      {/* ID (always read-only) */}
      <TextField
        label="Rolodex ID"
        value={person.id}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
        sx={{ backgroundColor: getBackgroundColor(false) }}
      />

      <Box display="flex" gap={2}>
        {/* Created At (read-only) */}
        <TextField
          label="Created At"
          value={formatDate(person.created_at)}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          sx={{
            width: "30%",
            backgroundColor: getBackgroundColor(false),
          }}
        />

        {/* Updated At (read-only) */}
        <TextField
          label="Updated At"
          value={person?.updated_at ? formatDate(person.updated_at) : "N/A"}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          sx={{
            width: "30%",
            backgroundColor: getBackgroundColor(false),
          }}
        />

        {/* Version (read-only) */}
        <TextField
          label="Version"
          value={person.version || ""}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          sx={{
            width: "10%",
            backgroundColor: getBackgroundColor(false),
          }}
        />

        {/* Published Version (read-only) */}
        <TextField
          label="Published Version"
          value={person.published_version || ""}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          sx={{
            width: "15%",
            backgroundColor: getBackgroundColor(editMode),
          }}
        />
        {/* Boosted Threshold */}
        <TextField
          label="Boosted Threshold"
          type="text"
          value={boostedThreshold}
          onChange={(e) => {
            if (!editMode) return;
            const inputValue = e.target.value;
            if (inputValue === "") {
              setBoostedThreshold(inputValue);
              return;
            }
            const validPattern = /^\d+(\.\d{0,2})?$/;
            if (validPattern.test(inputValue)) {
              setBoostedThreshold(inputValue);
            }
          }}
          onBlur={() => {
            if (boostedThreshold.trim() === "") {
              setBoostedThreshold("0");
            }
          }}
          fullWidth
          margin="normal"
          sx={{
            width: "15%",
            backgroundColor: getBackgroundColor(editMode),
          }}
          InputProps={{
            readOnly: !editMode,
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Box>

      {/* First Name */}
      <Box display="flex" gap={2}>
        <TextField
          label="First Name"
          value={firstName}
          onChange={(e) => editMode && setFirstName(e.target.value)}
          fullWidth
          margin="normal"
          sx={{
            width: "50%",
            backgroundColor: getBackgroundColor(editMode),
          }}
          InputProps={{ readOnly: !editMode }}
          InputLabelProps={{ shrink: true }}
        />
        {/* Phonetic First Name */}
        <TextField
          label="Phonetic First Name"
          value={firstNamePhonetic}
          onChange={(e) => editMode && setFirstNamePhonetic(e.target.value)}
          fullWidth
          margin="normal"
          sx={{
            width: "50%",
            backgroundColor: getBackgroundColor(editMode),
          }}
          InputProps={{ readOnly: !editMode }}
          InputLabelProps={{ shrink: true }}
        />
      </Box>

      {/* Middle Name */}
      <Box display="flex" gap={2}>
        <TextField
          label="Middle Name"
          value={middleName}
          onChange={(e) => editMode && setMiddleName(e.target.value)}
          fullWidth
          margin="normal"
          sx={{
            width: "50%",
            backgroundColor: getBackgroundColor(editMode),
          }}
          InputProps={{ readOnly: !editMode }}
          InputLabelProps={{ shrink: true }}
        />
        {/* Phonetic Middle Name */}
        <TextField
          label="Phonetic Middle Name"
          value={middleNamePhonetic}
          onChange={(e) => editMode && setMiddleNamePhonetic(e.target.value)}
          fullWidth
          margin="normal"
          sx={{
            width: "50%",
            backgroundColor: getBackgroundColor(editMode),
          }}
          InputProps={{ readOnly: !editMode }}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      {/* Last Name */}
      <Box display="flex" gap={2}>
        <TextField
          label="Last Name"
          value={lastName}
          onChange={(e) => editMode && setLastName(e.target.value)}
          fullWidth
          margin="normal"
          sx={{
            width: "50%",
            backgroundColor: getBackgroundColor(editMode),
          }}
          InputProps={{ readOnly: !editMode }}
          InputLabelProps={{ shrink: true }}
        />
        {/* Phonetic Last Name */}
        <TextField
          label="Phonetic Last Name"
          value={lastNamePhonetic}
          onChange={(e) => editMode && setLastNamePhonetic(e.target.value)}
          fullWidth
          margin="normal"
          sx={{
            width: "50%",
            backgroundColor: getBackgroundColor(editMode),
          }}
          InputProps={{ readOnly: !editMode }}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <TextField
        label="Email"
        value={email}
        onChange={(e) => editMode && handleEmailChange(e.target.value)}
        fullWidth
        margin="normal"
        sx={{ backgroundColor: getBackgroundColor(editMode) }}
        InputProps={{ readOnly: !editMode }}
        InputLabelProps={{ shrink: true }}
        error={!emailValid && editMode}
        helperText={!emailValid && editMode ? "Invalid Email Address" : ""}
      />
      {/* Conversation IDs */}
      {editMode ? (
        showConversationLoader ? (
          <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
            <CircularProgress />
            <Typography variant="body1" sx={{ ml: 2 }}>
              Loading Conversations...
            </Typography>
          </Box>
        ) : (
          <Autocomplete
            multiple
            options={conversations.map((c) => c.id)}
            value={selectedConversations}
            onChange={handleConversationsChange}
            // Render tags to color published vs. unpublished
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                const isPublished = publishedConvIds.includes(option);
                return (
                  <Chip
                    label={option}
                    // If published, disable removal
                    key={option}
                    onDelete={
                      isPublished ? undefined : getTagProps({ index }).onDelete
                    }
                    // Use a different color or style for published vs. non-published
                    sx={{
                      mr: 0.5,
                      mb: 0.5,
                      bgcolor: isPublished ? "#c8e6c9" : "#bbdefb",
                    }}
                  />
                );
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Conversation IDs"
                variant="outlined"
                margin="normal"
                placeholder="Select conversation IDs"
                sx={{ backgroundColor: getBackgroundColor(true) }}
              />
            )}
            sx={{ mt: 2 }}
          />
        )
      ) : (
        <TextField
          label="Conversation IDs"
          value={person.conversation_ids.join(", ")}
          fullWidth
          margin="normal"
          multiline
          minRows={2}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          sx={{ backgroundColor: getBackgroundColor(false) }}
        />
      )}

      {/* Summary */}
      <TextField
        label="Summary"
        fullWidth
        margin="normal"
        multiline
        minRows={3}
        value={summary}
        onChange={(e) => editMode && setSummary(e.target.value)}
        sx={{ backgroundColor: getBackgroundColor(editMode) }}
        InputProps={{ readOnly: !editMode }}
        InputLabelProps={{ shrink: true }}
      />

      {/* Buttons */}
      <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
        {!editMode ? (
          <Button variant="contained" onClick={handleToggleEdit}>
            Edit
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              onClick={handleOpenConfirmDialog}
              disabled={convoLoading}
            >
              Save Changes
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                // Revert changes
                const f = person.data?.name.first_name || "";
                const m = person.data?.name.middle_name || "";
                const l = person.data?.name.last_name || "";
                const fp = person.data?.name.first_name_phonetic || "";
                const mp = person.data?.name.middle_name_phonetic || "";
                const lp = person.data?.name.last_name_phonetic || "";
                setFirstName(f);
                setMiddleName(m);
                setLastName(l);
                setFirstNamePhonetic(fp);
                setMiddleNamePhonetic(mp);
                setLastNamePhonetic(lp);
                setSummary(person.data.summary || "");
                setSelectedConversations([...person.conversation_ids]);
                setEditMode(false);
              }}
            >
              Cancel
            </Button>
          </>
        )}

        <Button
          variant="contained"
          color="success"
          onClick={handleOpenPublishDialog}
          disabled={publishDisabled || editMode}
        >
          {publishDisabled ? "Latest Version Published" : "Publish"}
        </Button>

        <Button
          variant="contained"
          onClick={() => navigate("/dashboard/rolodex")}
        >
          Back
        </Button>
        {canCreateUsers && <CreateUserDialog rolodexPerson={person} />}
      </Box>

      {/* Confirm Save Dialog */}
      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Update Rolodex Person</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save these changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog}>Cancel</Button>
          <Button onClick={handleSaveChanges} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Publish Dialog */}
      <Dialog open={publishDialogOpen} onClose={handleClosePublishDialog}>
        <DialogTitle>Publish Rolodex Person</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Publishing will create SourcedInformation for each conversation and
            then set your published_version to match the current version. All
            current conversation ids will be published and can no longer be
            removed from this rolodex entry.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePublishDialog}>Cancel</Button>
          <Button onClick={handleConfirmPublish} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RolodexView;
