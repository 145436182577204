// src/components/AssetView/asset-content/BulletItemEditor.tsx

import React, { useState } from "react";
import { Box, TextField, IconButton, Tooltip, styled } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import ImageUpload from "./bullet-item/ImageUpload";
import ViewImageDialog from "./bullet-item/ViewImageDialog";
import { CopyAll } from "@mui/icons-material";

export interface AssetContentItem {
  text: string;
  image_url?: string;
}

// We can use "AssetContentItem" as the data structure,
// and also give it an "id" for drag-and-drop:
export interface BulletItem extends AssetContentItem {
  id: string;
}

interface BulletItemEditorProps {
  item: BulletItem;
  onChange: (newItem: BulletItem) => void;
  onRemove?: () => void;
  disabled?: boolean;
  dragHandle?: React.ReactNode;
}

const Thumbnail = styled("img")({});

const BulletItemEditor: React.FC<BulletItemEditorProps> = ({
  item,
  onChange,
  onRemove,
  disabled = false,
  dragHandle,
}) => {
  const [showImageDialog, setShowImageDialog] = useState(false);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...item, text: e.target.value });
  };

  const handleImageUrlChange = (url: string) => {
    onChange({ ...item, image_url: url });
  };

  const handleClearImage = () => {
    onChange({ ...item, image_url: "" });
  };

  return (
    <Box
      sx={{
        border: "1px solid #ccc",
        borderRadius: 2,
        p: 2,
        mb: 1,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
        backgroundColor: disabled ? "#f9f9f9" : "white",
      }}
    >
      <Box
        sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}
      >
        {dragHandle}
        {!disabled && onRemove && (
          <Tooltip title="Remove bullet">
            <IconButton color="error" onClick={onRemove}>
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      {/* Text Input */}
      <TextField
        label="Text"
        variant="outlined"
        size="small"
        fullWidth
        value={item.text}
        onChange={handleTextChange}
        disabled={disabled}
      />

      {/* Image Section */}
      <Box
        sx={{ display: "flex", alignItems: "center", gap: 2, width: "100%" }}
      >
        {/* Image Preview (Larger & Clickable) */}
        {item.image_url ? (
          <Box sx={{ position: "relative" }}>
            <Thumbnail
              src={item.image_url}
              onClick={() => setShowImageDialog(true)}
              sx={{
                width: 160,
                height: 120,
                borderRadius: 2,
                border: "1px solid #ccc",
                objectFit: "cover",
                cursor: "pointer",
              }}
            />
          </Box>
        ) : (
          !disabled && (
            <ImageUpload
              folder="assets"
              existingUrl={item.image_url}
              onUploadSuccess={handleImageUrlChange}
              disabled={disabled}
              smallButtons
            />
          )
        )}

        {item.image_url && (
          <Box sx={{ flex: 1 }}>
            <Tooltip title="Copy Image URL">
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(item.image_url || "")
                }
                size="small"
              >
                <CopyAll fontSize="small" />
              </IconButton>
            </Tooltip>

            {!disabled && (
              <Tooltip title="Remove Image">
                <IconButton onClick={handleClearImage} size="small">
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      </Box>

      <ViewImageDialog
        open={showImageDialog}
        imageUrl={item.image_url || ""}
        onClose={() => setShowImageDialog(false)}
      />
    </Box>
  );
};

export default BulletItemEditor;
