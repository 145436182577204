// StructuredLogsModal.tsx

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Modal,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
  Button,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import StructuredLogsViewer from "../StructuredLogsViewer";
import {
  LogSchema,
  Message,
} from "../../../../redux/reducers/conversationsReducer";
import { format } from "date-fns";

/**
 * Sub-component to display preceding context messages
 * around a particular message.
 */
interface ContextMessagesProps {
  messages: Message[];
  highlightMessageId?: number;
  getSpeakerName: (msg: Message) => string;
}
const ContextMessages: React.FC<ContextMessagesProps> = ({
  messages,
  highlightMessageId,
  getSpeakerName,
}) => {
  const highlightedRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (highlightedRef.current) {
      highlightedRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [messages, highlightMessageId]);

  return (
    <Box sx={{ height: 200, overflowY: "auto", mb: 2 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Context Around This Message
      </Typography>
      {messages.map((msg) => {
        const currentId = msg.id ?? msg.chat_message_id;
        const isHighlighted = currentId === highlightMessageId;
        const bgColor = isHighlighted
          ? "#fff9c4"
          : msg.type === "human"
            ? "#F0F0F0"
            : "#DCF8C6";

        const textAlign = msg.type === "human" ? "left" : "right";
        const formattedDate = format(new Date(msg.created_at), "PPpp");
        const speakerName = getSpeakerName(msg);

        return (
          <Box
            key={`${msg.chat_session_id}-${currentId}`}
            ref={isHighlighted ? highlightedRef : null}
            sx={{
              p: 1,
              mt: 1,
              borderRadius: 1,
              backgroundColor: bgColor,
              textAlign,
            }}
          >
            <Typography variant="subtitle2" color="textSecondary">
              {speakerName} - {formattedDate}
            </Typography>
            <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
              {msg.content}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

/**
 * Props for the main StructuredLogsModal
 * We get both production & dev logs from parent, plus loading states,
 * and a callback to actually fetch dev logs if user clicks 'Try Dev'.
 */
interface StructuredLogsModalProps {
  showLogsModal: boolean;
  setShowLogsModal: (val: boolean) => void;
  handleShowLogsModal: (schema: LogSchema) => void;

  structuredLogs: { event_logs?: any } | undefined;
  devStructuredLogs: { event_logs?: any } | undefined;

  loadingStructuredLogs: boolean; // is production logs loading?
  devLoadingStructuredLogs: boolean; // is dev logs loading?

  message: Message;
  getContextMessages: (messageId: number, chatSessionId: string) => Message[];
  getSpeakerName: (msg: Message) => string;
}

/**
 * A modal to display production logs or dev logs. If production logs
 * are empty, we display a "Try Dev" button to fetch dev logs using
 * handleShowLogsModal(LogSchema.DEV_EVENTSPROD).
 */
const StructuredLogsModal: React.FC<StructuredLogsModalProps> = ({
  showLogsModal,
  setShowLogsModal,
  handleShowLogsModal,
  structuredLogs,
  devStructuredLogs,
  loadingStructuredLogs,
  devLoadingStructuredLogs,
  message,
  getContextMessages,
  getSpeakerName,
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  const actionId =
    message.type === "human"
      ? message.triggered_by_action_id ?? "N/A"
      : message.responding_to ?? message.triggered_by_action_id ?? "N/A";

  // Whether user explicitly asked to see dev logs
  const [tryDevSchema, setTryDevSchema] = useState(false);

  // The final logs we actually show
  const [displayedLogs, setDisplayedLogs] = useState<{
    event_logs?: any;
  } | null>(null);

  // Sub-arrays of displayed logs
  const [llmCalls, setLlmCalls] = useState<any[]>([]);
  const [modelCalls, setModelCalls] = useState<any[]>([]);
  const [TTSEvents, setTTSEvents] = useState<any[]>([]);
  // Currently selected call
  const [selectedCall, setSelectedCall] = useState<any>(null);

  // If production logs are empty
  const prodIsEmpty =
    !!structuredLogs &&
    (!structuredLogs.event_logs ||
      Object.values(structuredLogs.event_logs).every(
        (arr) => !arr || (Array.isArray(arr) && arr.length === 0)
      ));

  /**
   * If user clicks "Try Dev Schema," set flag and call parent's handler
   * to fetch dev logs.
   */
  const handleTryDevSchema = () => {
    setTryDevSchema(true);
    handleShowLogsModal(LogSchema.DEV_EVENTSPROD);
  };

  /**
   * Decide which logs we display:
   * 1) If user clicked dev and dev logs exist -> dev logs
   * 2) else if prod logs exist -> prod logs
   * 3) else -> null
   */
  useEffect(() => {
    if (tryDevSchema && devStructuredLogs?.event_logs) {
      setDisplayedLogs(devStructuredLogs);
    } else if (structuredLogs?.event_logs) {
      setDisplayedLogs(structuredLogs);
    } else {
      setDisplayedLogs(null);
    }
  }, [tryDevSchema, devStructuredLogs, structuredLogs]);

  /**
   * Parse displayed logs into sub-lists.
   * Reset lists if no displayed logs.
   */
  useEffect(() => {
    if (!displayedLogs?.event_logs) {
      setLlmCalls([]);
      setModelCalls([]);
      setTTSEvents([]);
      setSelectedCall(null);
      return;
    }

    const eventLogs = displayedLogs.event_logs;
    let llmEvents = eventLogs.llm_inference_event || [];
    let modelEvents = eventLogs.model_inference_event || [];
    let ttsEvents = eventLogs.text_to_speech_saved_event || [];

    llmEvents = removeDuplicateInferenceIds(llmEvents);
    modelEvents = removeDuplicateInferenceIds(modelEvents);

    setLlmCalls(llmEvents);
    setModelCalls(modelEvents);
    setTTSEvents(ttsEvents);

    const firstCall = llmEvents[0] || modelEvents[0] || ttsEvents[0] || null;
    if (firstCall) {
      setSelectedCall(firstCall);
    } else {
      setSelectedCall(null);
    }
  }, [displayedLogs]);

  // Focus on modal so we can do ctrl+f
  useEffect(() => {
    if (showLogsModal && modalRef.current) {
      modalRef.current.focus();
    }
  }, [showLogsModal]);

  // Prepare context messages
  const contextId = message.id ?? message.chat_message_id;
  const contextMessages = getContextMessages(
    contextId,
    message.chat_session_id
  );

  // If ctrl/cmd+f in the modal, we do not bubble up
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((e.ctrlKey || e.metaKey) && e.key === "f") {
      e.stopPropagation();
    }
  };

  // Helper to remove duplicates by inference_id
  function removeDuplicateInferenceIds(events: any[]) {
    const uniqueEvents = new Map();
    for (const event of events) {
      const inferenceId =
        event.llm_inference_event?.inference_id ||
        event.model_inference_event?.inference_id ||
        event.text_to_speech_saved_event?.inference_id;
      if (inferenceId) {
        // Overwrite with shadow events if needed
        if (
          !uniqueEvents.has(inferenceId) ||
          event.llm_inference_event?.is_shadow
        ) {
          uniqueEvents.set(inferenceId, event);
        }
      } else {
        // No inference id, store with random key
        uniqueEvents.set(Math.random(), event);
      }
    }
    return Array.from(uniqueEvents.values());
  }

  return (
    <Modal open={showLogsModal} onClose={() => setShowLogsModal(false)}>
      <Box
        ref={modalRef}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: "80vh",
          bgcolor: "background.paper",
          borderRadius: 1,
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
          outline: "none",
        }}
      >
        {/* Modal Header */}
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h4">Message Events</Typography>
            <IconButton onClick={() => setShowLogsModal(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="subtitle1" sx={{ mt: 1 }}>
            Action ID: {actionId}
          </Typography>
        </Box>
        <Divider />


        {/* If user hasn't tried dev yet, and production logs are loading */}
        {loadingStructuredLogs && !tryDevSchema && (
          <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
            <CircularProgress size={24} />
            <Typography sx={{ ml: 2 }}>Loading production logs...</Typography>
          </Box>
        )}

        {/* If user tries dev, and dev logs are loading */}
        {tryDevSchema && devLoadingStructuredLogs && (
          <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
            <CircularProgress size={24} />
            <Typography sx={{ ml: 2 }}>Loading dev logs...</Typography>
          </Box>
        )}

        {/* 
          If production logs are empty, we have not tried dev yet => 
          show a "Try Dev" button 
        */}
        {!loadingStructuredLogs && prodIsEmpty && !tryDevSchema && (
          <Box sx={{ p: 2 }}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              No events found in production logs.
            </Typography>
            <Button variant="outlined" onClick={handleTryDevSchema}>
              Try Dev Schema
            </Button>
          </Box>
        )}

        {/* If no logs at all (prod or dev) & not loading => show "No logs" */}
        {!displayedLogs?.event_logs &&
          !(loadingStructuredLogs || devLoadingStructuredLogs) && (
            <Box sx={{ p: 2 }}>
              <Typography>No logs available.</Typography>
            </Box>
          )}

        {/* Main content if we do have logs */}
        {displayedLogs?.event_logs &&
          !(loadingStructuredLogs || devLoadingStructuredLogs) && (
            <Box sx={{ display: "flex", flex: 1, overflow: "hidden" }}>
              <Box
                sx={{
                  width: "30%",
                  borderRight: "1px solid #ccc",
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              >
                <Box sx={{ flex: 1, overflowY: "auto", p: 2 }}>
                  {/* LLM Calls */}
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    LLM Inference Calls
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <List dense>
                    {llmCalls.map((call, i) => {
                      const llmEvent = call.llm_inference_event;
                      const name = llmEvent?.model_name || "Unknown Model";
                      const selected = selectedCall === call;
                      const watcherName = call.context?.watcher?.name
                        ? `Watcher: ${call.context.watcher.name}`
                        : "";
                      return (
                        <ListItem
                          key={i}
                          button
                          selected={selected}
                          onClick={() => setSelectedCall(call)}
                        >
                          <ListItemText
                            primary={name}
                            secondary={watcherName}
                          />
                        </ListItem>
                      );
                    })}
                  </List>

                  {/* Model Calls */}
                  <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                    Model Inference Calls
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <List dense>
                    {modelCalls.map((call, i) => {
                      const modelEvent = call.model_inference_event;
                      const name = modelEvent?.model_key || "Unknown Model";
                      const selected = selectedCall === call;
                      const watcherName = call.context?.watcher?.name
                        ? `Watcher: ${call.context.watcher.name}`
                        : "";
                      return (
                        <ListItem
                          key={i}
                          button
                          selected={selected}
                          onClick={() => setSelectedCall(call)}
                        >
                          <ListItemText
                            primary={name}
                            secondary={watcherName}
                          />
                        </ListItem>
                      );
                    })}
                  </List>

                  {/* TTS Events */}
                  <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                    Text to Speech Saved Events
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <List dense>
                    {TTSEvents.map((call, i) => {
                      const ttsEvent = call.text_to_speech_saved_event;
                      const name = ttsEvent?.blob_path || "Unknown Event";
                      const selected = selectedCall === call;
                      return (
                        <ListItem
                          key={i}
                          button
                          selected={selected}
                          onClick={() => setSelectedCall(call)}
                        >
                          <ListItemText primary={name} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              </Box>

              {/* Right pane: context + details */}
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  p: 2,
                }}
              >
                {selectedCall ? (
                  <>
                    {contextMessages.length > 0 && (
                      <ContextMessages
                        messages={contextMessages}
                        highlightMessageId={contextId}
                        getSpeakerName={getSpeakerName}
                      />
                    )}

                    <Typography variant="h5" sx={{ mb: 1 }}>
                      Call Details
                    </Typography>

                    <Box
                      sx={{
                        flex: 1,
                        overflowY: "auto",
                        borderTop: "1px solid #ccc",
                        mt: 1,
                        pt: 1,
                      }}
                    >
                      <StructuredLogsViewer call={selectedCall} />
                    </Box>
                  </>
                ) : (
                  <Typography variant="body1">
                    Select a call to view details.
                  </Typography>
                )}
              </Box>
            </Box>
          )}
      </Box>
    </Modal>
  );
};

export default StructuredLogsModal;
