import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AppDispatch } from "../../../redux/store";
import { createConversation } from "../../../redux/actions/conversationsActions";
import { User } from "../../../redux/reducers/userReducer";
import { RootState } from "../../../redux/store";
import { Environment } from "../../../redux/reducers/baseReducer";
import { CreateConversationRequest } from "../../../redux/actions/userActions";

interface CreateConversationDialogProps {
    user: User;
}

const CreateConversationDialog: React.FC<CreateConversationDialogProps> = ({ user }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [open, setOpen] = useState(false);
    const [background, setBackground] = useState("");
    const [isFriend, setIsFriend] = useState(false);
    const [environment, setEnvironment] = useState("");

    const { loading } = useSelector((state: RootState) => state.users);

    useEffect(() => {
        if (user) {
            setEnvironment(user.environment || "");
            setIsFriend(false);
        }
    }, [user, open]);

    const isFormValid = environment.trim().length > 0;

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setBackground("");
        setEnvironment(user.environment || "");
        setIsFriend(false);
    };

    const handleSubmit = () => {
        const contextPayload: any = {
            background: background,
            is_friend: isFriend || null,
            environment: environment || null,
        };
        const conversationRequest: CreateConversationRequest = {
            user: user,
            context: contextPayload,
        };

        dispatch(createConversation(conversationRequest))
            .unwrap()
            .then(() => {
                toast.success("Conversation created successfully!");
                handleClose();
            })
            .catch(() => {
                toast.error("Failed to create conversation.");
            });
    };

    return (
        <Box>
            <Button variant="contained" size="small" color="secondary" onClick={handleOpen}>
                Create
            </Button>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>Create Conversation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Create a new conversation for {user.name?.first_name} {user.name?.last_name}.
                    </DialogContentText>
                    <TextField
                        margin="dense"
                        label="Background Info"
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={3}
                        value={background}
                        onChange={(e) => setBackground(e.target.value)}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isFriend}
                                onChange={(e) => setIsFriend(e.target.checked)}
                            />
                        }
                        label="Is Friend"
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Environment</InputLabel>
                        <Select
                            value={environment}
                            onChange={(e) => setEnvironment(e.target.value as string)}
                            label="Environment"
                        >
                            {Object.values(Environment).map((env) => (
                                <MenuItem key={env} value={env}>
                                    {env.slice(0)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={loading || !isFormValid}
                    >
                        {loading ? "Creating..." : "Create"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CreateConversationDialog;
