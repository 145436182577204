// src/components/AssetView/AssetView.tsx

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

import { AppDispatch, RootState } from "../../../redux/store";
import {
  fetchAsset,
  updateAsset,
  deleteAsset,
  publishAsset,
  makeStableAsset,
} from "../../../redux/actions/assetActions";
import { Asset, AssetContent } from "../../../redux/reducers/assetReducer";
import AssetContentEditor from "./AssetContentEditor";
import ViewMarkdownDialog from "./ViewMarkdownDialog";
import PreviewStoryDialog from "./PreviewStoryDialog";

const ALLOWED_ASSET_TYPES = [
  "knowledge_base",
  "strategy",
  "presentation",
] as const;

const formatDate = (isoString?: string): string => {
  if (!isoString) return "";
  return format(new Date(isoString), "MMM d, yyyy h:mm aaaa");
};

const AssetView: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { namespace, assetId } = useParams();

  const { currentAsset, loading, error } = useSelector(
    (state: RootState) => state.assets
  );

  const [editMode, setEditMode] = useState(false);
  const [confirmSaveOpen, setConfirmSaveOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmPublishOpen, setConfirmPublishOpen] = useState(false);
  const [confirmStableOpen, setConfirmStableOpen] = useState(false);

  const [localNamespace, setLocalNamespace] = useState("");
  const [localId, setLocalId] = useState("");
  const [localType, setLocalType] = useState<Asset["type"]>("knowledge_base");
  const [localContents, setLocalContents] = useState<AssetContent[]>([]);

  // For optional markdown viewer
  const [markdownOpen, setMarkdownOpen] = useState(false);
  const [markdownContent, setMarkdownContent] = useState("");

  // For story slideshow
  const [previewStoryIndex, setPreviewStoryIndex] = useState<number | null>(
    null
  );

  // Fetch asset when assetId/namespace change
  useEffect(() => {
    if (namespace && assetId) {
      dispatch(fetchAsset({ namespace, assetId }));
    }
  }, [dispatch, namespace, assetId]);

  useEffect(() => {
    if (
      currentAsset &&
      currentAsset.id === assetId &&
      currentAsset.namespace === namespace
    ) {
      setLocalNamespace(currentAsset.namespace);
      setLocalId(currentAsset.id);
      setLocalType(currentAsset.type);
      setLocalContents(currentAsset.contents);
    }
  }, [currentAsset, assetId, namespace]);

  // If loading or error, show it.
  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error}</div>;
  if (
    !currentAsset ||
    currentAsset.namespace !== namespace ||
    currentAsset.id !== assetId
  ) {
    return (
      <div>
        No asset found for {namespace}/{assetId}
      </div>
    );
  }

  const isDeleted = currentAsset.deleted;
  const isStable = currentAsset.namespace.endsWith("_stable");
  const readOnly = isDeleted || isStable;

  const handleToggleEdit = () => setEditMode((prev) => !prev);

  const handleRevertChanges = () => {
    if (currentAsset) {
      setLocalNamespace(currentAsset.namespace);
      setLocalId(currentAsset.id);
      setLocalType(currentAsset.type);
      setLocalContents(currentAsset.contents);
    }
    setEditMode(false);
  };

  const handleOpenConfirmSave = () => setConfirmSaveOpen(true);
  const handleCloseConfirmSave = () => setConfirmSaveOpen(false);

  const handleSaveChanges = () => {
    setConfirmSaveOpen(false);
    if (!currentAsset) return;
    const updatedContents = localContents.map((content) => {
      if (content.type === "story") {
        return {
          type: content.type,
          subject: content.subject,
          notes: content.notes,
          story_items: content.story_items,
          content_id: content?.content_id,
        };
      }
      return content;
    });
    const updatedAsset: Asset = {
      namespace: localNamespace,
      id: localId,
      type: localType,
      contents: updatedContents,
    };
    dispatch(updateAsset(updatedAsset))
      .unwrap()
      .then(() => {
        toast.success("Asset updated!");
        dispatch(fetchAsset({ namespace: localNamespace, assetId: localId }));
        setEditMode(false);
      })
      .catch((err) => {
        toast.error("Failed to update asset");
        console.error(err);
      });
  };

  const handleOpenConfirmDelete = () => setConfirmDeleteOpen(true);
  const handleCloseConfirmDelete = () => setConfirmDeleteOpen(false);

  const handleDelete = () => {
    setConfirmDeleteOpen(false);
    if (!currentAsset) return;
    dispatch(
      deleteAsset({
        namespace: currentAsset.namespace,
        assetId: currentAsset.id,
      })
    )
      .unwrap()
      .then(() => {
        toast.success("Asset deleted!");
        navigate("/dashboard/assets");
      })
      .catch((err) => {
        toast.error("Failed to delete asset");
        console.error(err);
      });
  };

  const handleOpenConfirmPublish = () => setConfirmPublishOpen(true);
  const handleCloseConfirmPublish = () => setConfirmPublishOpen(false);

  const handlePublish = () => {
    setConfirmPublishOpen(false);
    if (!currentAsset) return;
    dispatch(
      publishAsset({
        namespace: currentAsset.namespace,
        assetId: currentAsset.id,
      })
    )
      .unwrap()
      .then(() => {
        toast.success("Asset published!");
      })
      .catch((err) => {
        toast.error("Failed to publish asset");
        console.error(err);
      });
  };

  const handleOpenConfirmStable = () => setConfirmStableOpen(true);
  const handleCloseConfirmStable = () => setConfirmStableOpen(false);

  const handleMakeStable = () => {
    setConfirmStableOpen(false);
    if (!currentAsset) return;
    dispatch(
      makeStableAsset({
        namespace: currentAsset.namespace,
        assetId: currentAsset.id,
      })
    )
      .unwrap()
      .then(() => {
        toast.success("Asset made stable!");
      })
      .catch((err) => {
        toast.error("Failed to make stable asset");
        console.error(err);
      });
  };

  const handleAddContent = () => {
    setLocalContents((prev) => [
      ...prev,
      {
        type: "story",
        subject: "",
        story_items: [],
        faq_entry: "",
        faq_topic: "",
      },
    ]);
  };

  const handleRemoveContent = (idx: number) => {
    setLocalContents((prev) => prev.filter((_, i) => i !== idx));
  };

  const handleUpdateContent = (idx: number, updated: AssetContent) => {
    setLocalContents((prev) => prev.map((c, i) => (i === idx ? updated : c)));
  };

  // "View Markdown" callback
  const handleViewMarkdown = (markdown: string) => {
    setMarkdownContent(markdown);
    setMarkdownOpen(true);
  };

  const handlePreviewStory = (index: number) => {
    setPreviewStoryIndex(index);
  };

  return (
    <Box sx={{ p: 4 }}>
      <ToastContainer position="top-center" autoClose={3000} />
      <Typography variant="h5" gutterBottom>
        Asset Details
      </Typography>

      <TextField
        label="Namespace"
        value={localNamespace}
        onChange={(e) =>
          editMode && !readOnly && setLocalNamespace(e.target.value)
        }
        fullWidth
        margin="normal"
        disabled={!editMode || readOnly}
      />

      <TextField
        label="Asset ID"
        value={localId}
        onChange={(e) => editMode && !readOnly && setLocalId(e.target.value)}
        fullWidth
        margin="normal"
        disabled={!editMode || readOnly}
      />

      <Box>
        <Typography variant="body2" sx={{ fontWeight: "bold", mt: 1, mb: 0.5 }}>
          Asset Type
        </Typography>
        <Select
          value={localType}
          disabled={!editMode || readOnly}
          onChange={(e) => setLocalType(e.target.value as Asset["type"])}
          fullWidth
        >
          {ALLOWED_ASSET_TYPES.map((t) => (
            <MenuItem key={t} value={t}>
              {t}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <TextField
        label="Version"
        value={currentAsset.version}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Published Version"
        value={currentAsset.published_version}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Stable Version"
        value={currentAsset.stable_version}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Deleted?"
        value={currentAsset.deleted ? "Yes" : "No"}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Created At"
        value={formatDate(currentAsset.created_at)}
        fullWidth
        margin="normal"
        disabled
      />
      {currentAsset.deleted_at && (
        <TextField
          label="Deleted At"
          value={formatDate(currentAsset.deleted_at)}
          fullWidth
          margin="normal"
          disabled
        />
      )}

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" align="center">
        Contents
      </Typography>
      {localContents.map((content, idx) => (
        <AssetContentEditor
          key={idx}
          content={content}
          index={idx}
          disabled={!editMode || readOnly}
          onRemove={() => handleRemoveContent(idx)}
          onChange={(updated) => handleUpdateContent(idx, updated)}
          onPreviewStory={
            content.type === "story" ? () => handlePreviewStory(idx) : undefined
          }
          onViewMarkdown={handleViewMarkdown}
        />
      ))}
      {editMode && !readOnly && (
        <Box textAlign="center" my={2}>
          <Button variant="outlined" onClick={handleAddContent}>
            Add Content
          </Button>
        </Box>
      )}

      <Box display="flex" gap={2} mt={2}>
        {!editMode ? (
          <Button
            variant="contained"
            onClick={handleToggleEdit}
            disabled={readOnly}
          >
            {isDeleted ? "DELETED" : readOnly ? "Read Only" : "Edit"}
          </Button>
        ) : (
          <>
            <Button variant="contained" onClick={handleOpenConfirmSave}>
              Save
            </Button>
            <Button variant="outlined" onClick={handleRevertChanges}>
              Cancel
            </Button>
          </>
        )}
        {!isDeleted && (
          <Button
            variant="outlined"
            color="error"
            disabled={readOnly || editMode}
            onClick={handleOpenConfirmDelete}
          >
            Delete
          </Button>
        )}
        {!isDeleted && (
          <Button
            variant="contained"
            color="success"
            disabled={
              readOnly ||
              currentAsset.version === currentAsset.published_version ||
              editMode
            }
            onClick={handleOpenConfirmPublish}
          >
            {currentAsset.version === currentAsset.published_version
              ? "Already Published"
              : "Publish"}
          </Button>
        )}

        {!isDeleted && (
          <Button
            variant="contained"
            color="secondary"
            disabled={
              readOnly ||
              editMode ||
              currentAsset.version === currentAsset.stable_version
            }
            onClick={handleOpenConfirmStable}
          >
            {currentAsset.stable_version === currentAsset.version
              ? "Already Stable"
              : "Make Stable"}
          </Button>
        )}
        <Button
          variant="contained"
          onClick={() => navigate("/dashboard/assets")}
        >
          Back
        </Button>
      </Box>

      <Dialog open={confirmSaveOpen} onClose={handleCloseConfirmSave}>
        <DialogTitle>Save Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save your edits?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmSave}>Cancel</Button>
          <Button onClick={handleSaveChanges} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmDeleteOpen} onClose={handleCloseConfirmDelete}>
        <DialogTitle>Delete Asset</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to soft-delete this asset?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDelete}>Cancel</Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmPublishOpen} onClose={handleCloseConfirmPublish}>
        <DialogTitle>Publish Asset</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This sets the published_version to the current version and triggers
            memory reindex.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmPublish}>Cancel</Button>
          <Button onClick={handlePublish} autoFocus>
            Publish
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmStableOpen} onClose={handleCloseConfirmStable}>
        <DialogTitle>Make Stable Version</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This clones the asset to a stable namespace and sets stable_version.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmStable}>Cancel</Button>
          <Button onClick={handleMakeStable} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <ViewMarkdownDialog
        open={markdownOpen}
        markdown={markdownContent}
        onClose={() => setMarkdownOpen(false)}
      />

      {previewStoryIndex !== null &&
        previewStoryIndex < localContents.length && (
          <PreviewStoryDialog
            open
            content={localContents[previewStoryIndex]}
            onClose={() => setPreviewStoryIndex(null)}
          />
        )}
    </Box>
  );
};

export default AssetView;
