import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useStytchUser } from "@stytch/react";
import Fuse from "fuse.js";
import { debounce } from "lodash";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Autocomplete } from "@mui/material";

import { RootState, AppDispatch } from "../../redux/store";
import {
  fetchRolodex,
  createRolodexPerson,
} from "../../redux/actions/rolodexActions";
import { RolodexPerson } from "../../redux/reducers/rolodexReducer";
import { fetchConversations } from "../../redux/actions/conversationsActions";

const RolodexTab: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useStytchUser();

  const { rolodex, loading, error } = useSelector(
    (state: RootState) => state.rolodex
  );

  const { loading: conversationLoading } = useSelector(
    (state: RootState) => state.conversations
  );

  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const removeRolodexParams = useCallback(() => {
    if (searchParams.has("rolodex_id")) {
      searchParams.delete("rolodex_id");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const { conversations } = useSelector(
    (state: RootState) => state.conversations
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  // New Person form fields
  const [selectedConversations, setSelectedConversations] = useState<string[]>(
    []
  );
  const [newFirstName, setNewFirstName] = useState("");
  const [newFirstNamePhonetic, setNewFirstNamePhonetic] = useState("");
  const [newMiddleName, setNewMiddleName] = useState("");
  const [newMiddleNamePhonetic, setNewMiddleNamePhonetic] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newLastNamePhonetic, setNewLastNamePhonetic] = useState("");
  const [newSummary, setNewSummary] = useState("");
  const [newBoostedThreshold, setNewBoostedThreshold] = useState("0");
  const [newEmail, setNewEmail] = useState("");
  const [newEmailValid, setNewEmailValid] = useState(true);

  const debouncedFetchRolodex = useMemo(
    () => debounce(() => dispatch(fetchRolodex()), 300),
    [dispatch]
  );

  useEffect(() => {
    if (user) {
      debouncedFetchRolodex();
      // Also fetch all conversations for the Autocomplete if not loaded
      dispatch(fetchConversations());
    }
    return () => {
      debouncedFetchRolodex.cancel();
    };
  }, [user, debouncedFetchRolodex, dispatch]);

  const filteredData = useMemo(() => {
    if (!searchQuery) return rolodex;
    const fuse = new Fuse(rolodex, {
      keys: [
        "id",
        "data.name.first_name",
        "data.name.last_name",
        "data.summary",
      ],
      threshold: 0.3,
    });
    return fuse.search(searchQuery).map((res) => res.item);
  }, [rolodex, searchQuery]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const rolodexId = queryParams.get("rolodex_id");

    if (rolodexId && filteredData.length > 0) {
      removeRolodexParams();
      navigate(`/dashboard/rolodex/${rolodexId}`);
    }
  }, [filteredData, location.search, navigate, removeRolodexParams]);

  const handleView = useCallback((id: string) => {
    window.open(`/dashboard/rolodex/${id}`, "_blank");
  }, []);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 80,
        headerAlign: "center",
        align: "center",
        valueGetter: (params: any, row: any) => {
          const fn = row.data?.name?.first_name || "";
          const mn = row.data?.name?.middle_name || "";
          const ln = row.data?.name?.last_name || "";
          return [fn, mn, ln].filter(Boolean).join(" ");
        },
      },
      {
        field: "id",
        headerName: "ID",
        flex: 1,
        minWidth: 50,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "created_at",
        headerName: "Created At",
        flex: 0.5,
        headerAlign: "center",
        align: "center",
        minWidth: 100,
        valueGetter: (params: any, row: any) => {
          const date = new Date(row.created_at);
          return date.toLocaleString("en-US", {
            timeZone: "America/Los_Angeles",
          });
        },
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1.5,
        minWidth: 30,
        headerAlign: "center",
        align: "center",
        valueGetter: (params: any, row: any) => row.data?.email || "",
      },
      {
        field: "versions",
        headerName: "Version (cur::pub)",
        flex: 1,
        minWidth: 30,
        headerAlign: "center",
        align: "center",
        valueGetter: (params: any, row: any) =>
          (row.version || "") + "::" + (row.published_version || ""),
      },
      {
        field: "published_conversations",
        headerName: "Pub Conversations",
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        valueGetter: (params: any, row: any) => {
          const publishedIds = row.published_conversation_ids || [];
          return publishedIds.join(", ");
        },
      },
      {
        field: "actions",
        headerName: "",
        flex: 1,
        minWidth: 50,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const row = params.row as RolodexPerson;
          return (
            <Button variant="contained" onClick={() => handleView(row.id)}>
              View
            </Button>
          );
        },
      },
    ],
    [handleView]
  );

  const debouncedFetchConversations = useMemo(
    () => debounce(() => dispatch(fetchConversations()), 300),
    [dispatch]
  );

  const handleOpenCreateDialog = () => {
    setCreateDialogOpen(true);
    debouncedFetchConversations();
  };

  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
    setSelectedConversations([]);
    setNewFirstName("");
    setNewFirstNamePhonetic("");
    setNewMiddleName("");
    setNewMiddleNamePhonetic("");
    setNewLastName("");
    setNewLastNamePhonetic("");
    setNewSummary("");
    setNewEmail("");
  };

  // 1) Memoize checks for duplicate email or name
  const existingRolodexWithEmail = useMemo(() => {
    if (!newEmail.trim()) return false;
    return rolodex.some(
      (p) =>
        (p.data.email || "").toLowerCase() === newEmail.trim().toLowerCase()
    );
  }, [rolodex, newEmail]);

  const existingRolodexWithName = useMemo(() => {
    if (!newFirstName.trim() && !newLastName.trim()) return false;
    return rolodex.some(
      (p) =>
        (p.data.name.first_name || "").toLowerCase() ===
          newFirstName.trim().toLowerCase() &&
        (p.data.name.last_name || "").toLowerCase() ===
          newLastName.trim().toLowerCase()
    );
  }, [rolodex, newFirstName, newLastName]);

  const handleCreatePerson = () => {
    if (!newEmailValid) {
      alert("Please enter a valid email before creating.");
      return;
    }

    // 2) Hard-block if the email is already in use
    if (existingRolodexWithEmail) {
      alert(
        "A Rolodex person with that email already exists. Cannot create another."
      );
      return;
    }

    // 3) Warn if the exact same full name exists
    if (existingRolodexWithName) {
      const proceed = window.confirm(
        "A Rolodex person with the exact same name exists. Do you still want to create this new entry?"
      );
      if (!proceed) {
        return;
      }
    }

    dispatch(
      createRolodexPerson({
        conversation_ids: selectedConversations,
        data: {
          name: {
            first_name: newFirstName,
            first_name_phonetic: newFirstNamePhonetic,
            middle_name: newMiddleName,
            middle_name_phonetic: newMiddleNamePhonetic,
            last_name: newLastName,
            last_name_phonetic: newLastNamePhonetic,
          },
          summary: newSummary,
          boosted_threshold: Number(newBoostedThreshold),
          email: newEmail,
        },
      })
    )
      .unwrap()
      .then((newPerson) => {
        handleCloseCreateDialog();
        navigate(`/dashboard/rolodex/${newPerson.id}`);
      })
      .catch((err) => {
        console.error("Failed to create new rolodex person", err);
      });
  };

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error}</div>;

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        <TextField
          label="Search Rolodex"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ flex: 1 }}
        />
        <Button variant="outlined" onClick={() => setSearchQuery("")}>
          Reset
        </Button>
        <Button variant="contained" onClick={handleOpenCreateDialog}>
          Create New Person
        </Button>
      </Box>

      <Box sx={{ height: "70vh", width: "100%" }}>
        <DataGrid rows={filteredData} columns={columns} />
      </Box>

      {/* Create Person Dialog */}
      <Dialog
        open={createDialogOpen}
        onClose={handleCloseCreateDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Create Rolodex Person</DialogTitle>
        <DialogContent>
          {conversationLoading ? (
            <>
              Loading Conversations... <CircularProgress size="1rem" />
            </>
          ) : (
            <>
              <DialogContentText>
                Enter details for a new Rolodex person.
              </DialogContentText>
              <Box sx={{ display: "flex", gap: 2 }}>
                <TextField
                  margin="dense"
                  label="First Name"
                  fullWidth
                  variant="outlined"
                  value={newFirstName}
                  onChange={(e) => setNewFirstName(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="First Name Pronunciation (Optional)"
                  fullWidth
                  variant="outlined"
                  value={newFirstNamePhonetic}
                  onChange={(e) => setNewFirstNamePhonetic(e.target.value)}
                />
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <TextField
                  margin="dense"
                  label="Middle Name"
                  fullWidth
                  variant="outlined"
                  value={newMiddleName}
                  onChange={(e) => setNewMiddleName(e.target.value)}
                  sx={{ mt: 2 }}
                />
                <TextField
                  margin="dense"
                  label="Middle Name Pronunciation (Optional)"
                  fullWidth
                  variant="outlined"
                  value={newMiddleNamePhonetic}
                  onChange={(e) => setNewMiddleNamePhonetic(e.target.value)}
                  sx={{ mt: 2 }}
                />
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <TextField
                  margin="dense"
                  label="Last Name"
                  fullWidth
                  variant="outlined"
                  value={newLastName}
                  onChange={(e) => setNewLastName(e.target.value)}
                  sx={{ mt: 2 }}
                />
                <TextField
                  margin="dense"
                  label="Last Name Pronunciation (Optional)"
                  fullWidth
                  variant="outlined"
                  value={newLastNamePhonetic}
                  onChange={(e) => setNewLastNamePhonetic(e.target.value)}
                  sx={{ mt: 2 }}
                />
              </Box>
              <TextField
                label="Boosted Threshold"
                type="text"
                value={newBoostedThreshold}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const validPattern = /^\d+(\.\d{0,2})?$/;
                  if (inputValue === "") {
                    setNewBoostedThreshold(inputValue);
                  } else if (validPattern.test(inputValue)) {
                    setNewBoostedThreshold(inputValue);
                  }
                }}
                onBlur={() => {
                  if (newBoostedThreshold.trim() === "") {
                    setNewBoostedThreshold("0");
                  }
                }}
                fullWidth
                sx={{ mt: 2 }}
              />
              <TextField
                margin="dense"
                label="Email"
                fullWidth
                variant="outlined"
                value={newEmail}
                onChange={(e) => {
                  const val = e.target.value;
                  setNewEmail(val);
                  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  setNewEmailValid(!val || pattern.test(val));
                }}
                sx={{ mt: 2 }}
                error={!newEmailValid}
                helperText={!newEmailValid ? "Invalid Email" : ""}
              />
              <TextField
                margin="dense"
                label="Summary"
                fullWidth
                variant="outlined"
                value={newSummary}
                onChange={(e) => setNewSummary(e.target.value)}
                multiline
                minRows={2}
                sx={{ mt: 2 }}
              />
              <Autocomplete
                multiple
                options={conversations.map((c) => c.id)}
                value={selectedConversations}
                onChange={(_, newValue) => setSelectedConversations(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Conversations"
                    placeholder="Select conversation IDs"
                  />
                )}
                sx={{ mt: 2 }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleCreatePerson}
            disabled={
              conversationLoading ||
              (!newFirstName && !newLastName && !newMiddleName) ||
              selectedConversations.length === 0
            }
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RolodexTab;
