import React, { useEffect, useMemo, useState } from "react";
import { useStytch, useStytchUser } from "@stytch/react";
import {
  useNavigate,
  useLocation,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import {
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  Typography,
  Button,
  Container,
  Box,
} from "@mui/material";
import UsersTab from "./dashboard/UsersTab";
import ConversationsTab from "./dashboard/ConversationsTab";
import SafetyIncidentsTab from "./dashboard/SafetyIncidentsTab";
import "./Dashboard.css";
import UserView from "./dashboard/users/UserView";
import ConversationView from "./dashboard/conversations/ConversationView";
import SafetyIncidentView from "./dashboard/safety-incidents/SafetyIncidentView";
import RolodexTab from "./dashboard/RolodexTab";
import RolodexView from "./dashboard/rolodex/RolodexView";
import { NotFound } from "../App";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { debounce } from "lodash";
import { fetchUser } from "../redux/actions/baseActions";
import AccessDenied from "./dashboard/AccessDenied";
import AssetTab from "./dashboard/AssetTab";
import AssetView from "./dashboard/assets/AssetView";

const Dashboard: React.FC = () => {
  const stytch = useStytch();
  const { user } = useStytchUser();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const {
    user: adminUser,
    loading: userLoading,
    error: userError,
  } = useSelector((state: RootState) => state.base);

  const debouncedFetchUser = useMemo(
    () => debounce(() => dispatch(fetchUser()), 300),
    [dispatch]
  );

  useEffect(() => {
    if (user) {
      debouncedFetchUser();
    }
    return () => {
      debouncedFetchUser.cancel();
    };
  }, [debouncedFetchUser, user]);

  const [selectedTab, setSelectedTab] = useState("conversations");

  const handleLogout = async () => {
    await stytch.session.revoke();
    navigate("/login");
    localStorage.removeItem("jwt");
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    const tokenType = queryParams.get("stytch_token_type");
    if (!user && !(token || tokenType)) {
      navigate(
        `/login?redirect=${encodeURIComponent(
          location.pathname + location.search
        )}`
      );
    }
  }, [user, navigate, location]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const redirect = queryParams.get("redirect");
    if (redirect && user) {
      navigate(redirect);
    }
  }, [user, navigate, location]);

  // Determine if the user has access to certain routes
  const canViewUsers = adminUser?.scopes?.includes("userview:view");
  const canViewConversations = adminUser?.scopes?.includes(
    "conversationview:view"
  );
  const canViewSafetyIncidents = adminUser?.scopes?.includes(
    "safetyincidentsview:view"
  );
  const canViewAnnotations = adminUser?.scopes?.includes("annotationview:view");

  const canViewRolodex = adminUser?.scopes?.includes("rolodexview:view");

  const canViewAssets = adminUser?.scopes?.includes("assetview:view");

  useEffect(() => {
    if (adminUser) {
      switch (location.pathname) {
        case "/dashboard/users":
        case "/dashboard/users/":
          if (canViewUsers) {
            setSelectedTab("users");
          } else {
            navigate("/dashboard" + location.search);
          }
          break;
        case "/dashboard/conversations":
        case "/dashboard/conversations/":
          if (canViewConversations || canViewAnnotations) {
            setSelectedTab("conversations");
          } else {
            navigate("/dashboard" + location.search);
          }
          break;
        case "/dashboard/safety-incidents":
        case "/dashboard/safety-incidents/":
          if (canViewSafetyIncidents) {
            setSelectedTab("safety-incidents");
          } else {
            navigate("/dashboard" + location.search);
          }
          break;
        // Add case for /dashboard/rolodex
        case "/dashboard/rolodex":
        case "/dashboard/rolodex/":
          if (canViewRolodex) {
            setSelectedTab("rolodex");
          } else {
            navigate("/dashboard" + location.search);
          }
          break;
        case "/dashboard/assets":
        case "/dashboard/assets/":
          if (canViewAssets) {
            setSelectedTab("assets");
          } else {
            navigate("/dashboard" + location.search);
          }
          break;
        case "/dashboard":
          // Automatically navigate to the first accessible tab
          if (canViewUsers) {
            navigate("/dashboard/users" + location.search);
            setSelectedTab("users");
          } else if (canViewConversations || canViewAnnotations) {
            navigate("/dashboard/conversations" + location.search);
            setSelectedTab("conversations");
          } else if (canViewSafetyIncidents) {
            navigate("/dashboard/safety-incidents" + location.search);
            setSelectedTab("safety-incidents");
          } else if (canViewRolodex) {
            navigate("/dashboard/rolodex" + location.search);
            setSelectedTab("rolodex");
          } else if (canViewAssets) {
            navigate("/dashboard/assets" + location.search);
            setSelectedTab("assets");
          }
          break;
      }
    }
  }, [
    location.pathname,
    navigate,
    location,
    canViewUsers,
    canViewConversations,
    canViewSafetyIncidents,
    canViewAnnotations,
    canViewRolodex,
    canViewAssets,
    adminUser,
  ]);

  if (userLoading) {
    return <div>Loading...</div>;
  }
  if (!user) {
    return <NotFound />;
  }

  if (userError) {
    return <div>Error: {userError}</div>;
  }

  const hasAnyAccess =
    canViewUsers ||
    canViewConversations ||
    canViewSafetyIncidents ||
    canViewAnnotations ||
    canViewRolodex ||
    canViewAssets;

  // If the user has no access, redirect to the Access Denied page
  if (!hasAnyAccess) {
    return <AccessDenied />;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="transparent" sx={{ boxShadow: 1 }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Admin Portal
          </Typography>
          <Button color="primary" variant="contained" onClick={handleLogout}>
            Log out
          </Button>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 4 }}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          {canViewUsers && (
            <Tab
              label="Users"
              component={Link}
              to="/dashboard/users"
              value={"users"}
            />
          )}
          {(canViewConversations || canViewAnnotations) && (
            <Tab
              label="Conversations"
              component={Link}
              to="/dashboard/conversations"
              value={"conversations"}
            />
          )}
          {canViewSafetyIncidents && (
            <Tab
              label="Safety Incidents"
              component={Link}
              to="/dashboard/safety-incidents"
              value={"safety-incidents"}
            />
          )}
          {canViewRolodex && (
            <Tab
              label="Rolodex"
              component={Link}
              to="/dashboard/rolodex"
              value={"rolodex"}
            />
          )}
          {canViewAssets && (
            <Tab
              label="Assets"
              value="assets"
              component={Link}
              to="/dashboard/assets"
            />
          )}
        </Tabs>
        <Routes>
          {canViewUsers && (
            <Route path="users/:userId" element={<UserView />} />
          )}
          {canViewUsers && <Route path="users" element={<UsersTab />} />}
          {(canViewConversations || canViewAnnotations) && (
            <Route
              path="conversations/:conversationId"
              element={
                <ConversationView canReadConversation={canViewConversations} />
              }
            />
          )}
          {(canViewConversations || canViewAnnotations) && (
            <Route
              path="conversations"
              element={
                <ConversationsTab canReadConversation={canViewConversations} />
              }
            />
          )}
          {canViewSafetyIncidents && (
            <Route
              path="safety-incidents/:incidentId"
              element={<SafetyIncidentView />}
            />
          )}
          {canViewSafetyIncidents && (
            <Route path="safety-incidents" element={<SafetyIncidentsTab />} />
          )}
          {/* Rolodex routes */}
          {canViewRolodex && (
            <>
              <Route path="rolodex" element={<RolodexTab />} />
              <Route path="rolodex/:personId" element={<RolodexView />} />
            </>
          )}
          {canViewAssets && (
            <>
              <Route path="assets" element={<AssetTab />} />
              <Route
                path="assets/:namespace/:assetId"
                element={<AssetView />}
              />
            </>
          )}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Container>
    </Box>
  );
};

export default Dashboard;
