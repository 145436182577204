// src/components/AssetTab/AssetTab.tsx

import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  Chip,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { debounce } from "lodash";
import Fuse from "fuse.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AppDispatch, RootState } from "../../redux/store";
import { fetchAssets, createAsset } from "../../redux/actions/assetActions";
import { Asset, AssetContent } from "../../redux/reducers/assetReducer";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AssetContentEditor from "./assets/AssetContentEditor";

const ALLOWED_ASSET_TYPES = [
  "knowledge_base",
  "strategy",
  "presentation",
] as const;

const AssetTab: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const {
    items: assets,
    loading,
    error,
  } = useSelector((state: RootState) => state.assets);

  const [searchQuery, setSearchQuery] = useState("");
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  // For new asset creation
  const [newNamespace, setNewNamespace] = useState("");
  const [newAssetId, setNewAssetId] = useState("");
  const [newType, setNewType] = useState<string>("knowledge_base");
  const [newContents, setNewContents] = useState<AssetContent[]>([]);

  // Debounced fetch to avoid rapid calls
  useEffect(() => {
    const debounced = debounce(() => dispatch(fetchAssets()), 300);
    debounced();
    return () => {
      debounced.cancel();
    };
  }, [dispatch]);

  // Filter the assets by search
  const filteredAssets = useMemo(() => {
    if (!searchQuery) return assets;
    const fuse = new Fuse(assets, {
      keys: ["id", "namespace", "type"],
      threshold: 0.3,
    });
    return fuse.search(searchQuery).map((res) => res.item);
  }, [assets, searchQuery]);

  const renderBooleanCell = (params: any) => (
    <p style={{ textAlign: "center", margin: 0 }}>
      {params.value ? "✔️" : "❌"}
    </p>
  );

  // DataGrid columns, with version fields as small numeric columns
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "namespace",
        headerName: "Namespace",
        flex: 1.5,
        minWidth: 120,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const namespace = params.value as string;
          const isStable = namespace.endsWith("_stable");
          const displayNamespace = isStable
            ? namespace.replace("_stable", "")
            : namespace;

          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1}
              height="100%"
            >
              <Typography variant="body2">{displayNamespace}</Typography>
              {isStable && (
                <Chip
                  label="Stable"
                  color="error"
                  size="small"
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: "bold",
                    height: "22px",
                  }}
                />
              )}
            </Box>
          );
        },
      },
      {
        field: "id",
        headerName: "Asset ID",
        flex: 1,
        minWidth: 100,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "type",
        headerName: "Type",
        flex: 1,
        minWidth: 120,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "deleted",
        headerName: "Is Deleted?",
        flex: 1,
        minWidth: 60,
        headerAlign: "center",
        align: "center",
        renderCell: renderBooleanCell,
      },
      {
        field: "version",
        headerName: "Version",
        type: "number",
        flex: 0.5,
        minWidth: 60,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "published_version",
        headerName: "PubVer",
        type: "number",
        flex: 0.5,
        minWidth: 60,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "stable_version",
        headerName: "StableVer",
        type: "number",
        flex: 0.5,
        minWidth: 60,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const namespace = params.row.namespace as string;
          return namespace.endsWith("_stable") ? "N/A" : params.value;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        minWidth: 100,
        sortable: false,
        renderCell: (params) => {
          const row = params.row as Asset;
          return (
            <Button
              variant="contained"
              onClick={() =>
                navigate(`/dashboard/assets/${row.namespace}/${row.id}`)
              }
            >
              View
            </Button>
          );
        },
        headerAlign: "center",
        align: "center",
      },
    ],
    [navigate]
  );

  // Handlers for create-asset dialog
  const handleOpenCreateDialog = () => {
    setCreateDialogOpen(true);
    setNewNamespace("");
    setNewAssetId("");
    setNewType("knowledge_base");
    setNewContents([]);
  };

  const handleCloseCreateDialog = () => setCreateDialogOpen(false);

  const handleAddNewContent = () => {
    setNewContents((prev) => [
      ...prev,
      {
        type: "story",
        subject: "",
        story_items: [],
        faq_entry: "",
        faq_topic: "",
      },
    ]);
  };

  const handleRemoveContent = (idx: number) => {
    setNewContents((prev) => prev.filter((_, i) => i !== idx));
  };

  const handleUpdateContent = (idx: number, updated: AssetContent) => {
    setNewContents((prev) => prev.map((c, i) => (i === idx ? updated : c)));
  };

  const handleCreateAsset = async () => {
    const requestBody = {
      namespace: newNamespace,
      id: newAssetId,
      type: newType,
      contents: newContents.map((c) => ({
        type: c.type,
        subject: c.subject,
        story_items: c.story_items,
        faq_entry: c.faq_entry,
        faq_topic: c.faq_topic,
      })),
    };
    try {
      const created = await dispatch(createAsset(requestBody)).unwrap();
      setCreateDialogOpen(false);
      navigate(`/dashboard/assets/${created.namespace}/${created.id}`);
    } catch (err) {
      console.error("Failed to create asset:", err);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <Box sx={{ width: "100%", mt: 2 }}>
        {/* Top filter row, with search & create button, similar to UsersTab */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            width: "100%",
            mb: 2,
            alignItems: "center",
          }}
        >
          <TextField
            label="Search Assets"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ flex: 1 }}
          />
          <Button variant="contained" onClick={handleOpenCreateDialog}>
            Create Asset
          </Button>
        </Box>

        {/* DataGrid area */}
        <Box sx={{ height: "65vh", width: "100%" }}>
          <DataGrid
            rows={filteredAssets}
            columns={columns}
            getRowId={(row) => `${row.namespace}:${row.id}`}
          />
        </Box>
      </Box>

      {/* Create Asset Dialog */}
      <Dialog
        open={createDialogOpen}
        onClose={handleCloseCreateDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Create New Asset</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill in details for the new asset. You can have multiple contents,
            each with bullet items.
          </DialogContentText>

          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <TextField
              label="Namespace"
              fullWidth
              value={newNamespace}
              onChange={(e) => setNewNamespace(e.target.value)}
            />
            <TextField
              label="Asset ID"
              fullWidth
              value={newAssetId}
              onChange={(e) => setNewAssetId(e.target.value)}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Asset Type</Typography>
            <Select
              value={newType}
              onChange={(e) => setNewType(e.target.value as string)}
              fullWidth
            >
              {ALLOWED_ASSET_TYPES.map((opt) => (
                <MenuItem key={opt} value={opt}>
                  {opt}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Contents</Typography>
            {newContents.map((content, idx) => (
              <AssetContentEditor
                key={idx}
                content={content}
                index={idx}
                disabled={false}
                onRemove={() => handleRemoveContent(idx)}
                onChange={(updated) => handleUpdateContent(idx, updated)}
              />
            ))}
            <Tooltip title="Add new content">
              <IconButton onClick={handleAddNewContent} color="primary">
                <AddCircleOutlineIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog}>Cancel</Button>
          <Button
            onClick={handleCreateAsset}
            variant="contained"
            disabled={!newNamespace || !newAssetId}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AssetTab;
