import {
  createSlice,
  PayloadAction,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import {
  fetchAssets,
  fetchAsset,
  createAsset,
  updateAsset,
  deleteAsset,
  publishAsset,
  makeStableAsset,
} from "../actions/assetActions";

export interface AssetContentItem {
  text: string;
  image_url?: string;
}

export interface AssetContent {
  content_id?: string;
  type: "story" | "unknown" | "faq_entry";

  // story fields
  subject?: string;
  notes?: string;
  story_items: AssetContentItem[];

  // FAQ fields
  faq_topic?: string;
  faq_entry?: string;
}

export interface Asset {
  id: string;
  namespace: string;
  type: "knowledge_base" | "strategy" | "presentation";
  deleted?: boolean;
  version?: number;
  published_version?: number;
  stable_version?: number;
  created_at?: string;
  deleted_at?: string | null;
  contents: AssetContent[];
}

/** Redux State */
export interface AssetsState {
  items: Asset[];
  currentAsset?: Asset;
  loading: boolean;
  error: string | null;
}

/** Initial Redux State */
const initialState: AssetsState = {
  items: [],
  loading: false,
  error: null,
};

/** Helper function to convert markdown bullets to structured items */
const parseMarkdownToItems = (markdown: string): AssetContentItem[] => {
  return markdown
    .split("\n")
    .map((line) => line.trim())
    .filter((line) => line.startsWith("*"))
    .map((line) => {
      const withoutStar = line.replace(/^\*\s*/, "");
      const match = withoutStar.match(/\[\]\((.*?)\)/);
      if (match) {
        return {
          text: withoutStar.replace(`[](${match[1]})`, "").trim(),
          image_url: match[1],
        };
      }
      return { text: withoutStar };
    });
};

/** Convert DBAsset to structured Asset */
const transformDBAsset = (dbAsset: any): Asset => ({
  id: dbAsset.id,
  namespace: dbAsset.namespace,
  type: dbAsset.type,
  deleted: dbAsset.deleted || false,
  version: dbAsset.version || 1,
  published_version: dbAsset.published_version || 0,
  stable_version: dbAsset.stable_version || 0,
  created_at: dbAsset.created_at,
  deleted_at: dbAsset.deleted_at,
  contents: dbAsset.contents.map((content: any) => {
    if (content.type === "story") {
      // If it's a 'story', parse subject and markdown -> story_items
      return {
        content_id: content.content_id,
        type: "story",
        subject: content.story?.subject || "",
        notes: content.story?.notes || "",
        story_items: content.story?.markdown
          ? parseMarkdownToItems(content.story.markdown)
          : [],
        faq_topic: "",
        faq_entry: "",
      };
    } else if (content.type === "faq_entry") {
      // If it's a 'faq_entry', parse topic and entry
      return {
        content_id: content.content_id,
        type: "faq_entry",
        subject: "", // not used for FAQ
        story_items: [], // not used for FAQ
        notes: "", // not used for FAQ
        faq_topic: content.faq_entry?.topic || "",
        faq_entry: content.faq_entry?.entry || "",
      };
    } else {
      // Unknown or other type
      return {
        content_id: content.content_id,
        type: "unknown",
        subject: "",
        notes: "",
        story_items: [],
        faq_topic: "",
        faq_entry: "",
      };
    }
  }),
});

const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    clearCurrentAsset(state) {
      state.currentAsset = undefined;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<AssetsState>) => {
    builder.addCase(fetchAssets.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      fetchAssets.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.items = action.payload.map(transformDBAsset);
      }
    );
    builder.addCase(fetchAssets.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Failed to fetch assets.";
    });

    builder.addCase(fetchAsset.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.currentAsset = undefined;
    });
    builder.addCase(
      fetchAsset.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.currentAsset = transformDBAsset(action.payload);
      }
    );
    builder.addCase(fetchAsset.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Failed to fetch asset.";
    });

    builder.addCase(
      createAsset.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.items.push(transformDBAsset(action.payload));
      }
    );

    builder.addCase(
      updateAsset.fulfilled,
      (state, action: PayloadAction<any>) => {
        const updated = transformDBAsset(action.payload);
        const index = state.items.findIndex(
          (a) => a.id === updated.id && a.namespace === updated.namespace
        );
        if (index >= 0) state.items[index] = updated;
        if (state.currentAsset?.id === updated.id) state.currentAsset = updated;
      }
    );

    builder.addCase(
      deleteAsset.fulfilled,
      (state, action: PayloadAction<any>) => {
        const deleted = transformDBAsset(action.payload);
        const index = state.items.findIndex(
          (a) => a.id === deleted.id && a.namespace === deleted.namespace
        );
        if (index >= 0) state.items[index] = deleted;
        if (state.currentAsset?.id === deleted.id) state.currentAsset = deleted;
      }
    );

    builder.addCase(
      publishAsset.fulfilled,
      (state, action: PayloadAction<any>) => {
        const updated = transformDBAsset(action.payload);
        const index = state.items.findIndex(
          (a) => a.id === updated.id && a.namespace === updated.namespace
        );
        if (index >= 0) state.items[index] = updated;
        if (state.currentAsset?.id === updated.id) state.currentAsset = updated;
      }
    );

    builder.addCase(
      makeStableAsset.fulfilled,
      (state, action: PayloadAction<any>) => {
        const updated = transformDBAsset(action.payload);
        const index = state.items.findIndex(
          (a) => a.id === updated.id && a.namespace === updated.namespace
        );
        if (index >= 0) state.items[index] = updated;
        if (state.currentAsset?.id === updated.id) state.currentAsset = updated;
      }
    );
  },
});

export const { clearCurrentAsset } = assetsSlice.actions;
export default assetsSlice.reducer;
