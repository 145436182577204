import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../redux/store";
import { uploadPublicAsset } from "../../../../../redux/actions/assetActions";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  Button,
  Alert,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { getMediaBaseUrl } from "../../../../../utils/render";

interface ImageUploadProps {
  folder: string;
  onUploadSuccess: (url: string) => void;
  existingUrl?: string;
  disabled?: boolean;
  smallButtons?: boolean;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  folder,
  onUploadSuccess,
  existingUrl,
  disabled = false,
  smallButtons = false,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
      setError(null); // clear any past error whenever new file chosen
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;
    setUploading(true);
    setError(null);
    try {
      const result = await dispatch(
        uploadPublicAsset({ folder, file: selectedFile })
      ).unwrap();

      if (result.url) {
        onUploadSuccess(`${getMediaBaseUrl()}${result.url}`);
      }
    } catch (err) {
      console.error("Image upload failed:", err);
      setError("Upload failed. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  const handleReset = () => {
    setSelectedFile(null);
    setError(null);
  };

  const iconSize = smallButtons ? "small" : "medium";

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {/* Existing file info or "No file" */}
      {!existingUrl && (
        <Typography variant="caption" sx={{ flexShrink: 0 }}>
          {selectedFile ? `Selected: ${selectedFile.name}` : "No file"}
        </Typography>
      )}

      {/* Error display */}
      {error && (
        <Alert severity="error" sx={{ mb: 1 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {/* File chooser */}
        {!disabled && (
          <Tooltip title="Choose file">
            <IconButton
              component="label"
              size={iconSize}
              disabled={uploading}
              color="primary"
            >
              <PhotoCameraIcon fontSize={iconSize} />
              <input
                type="file"
                hidden
                onChange={handleFileChange}
                accept="image/*"
              />
            </IconButton>
          </Tooltip>
        )}

        {/* Upload button */}
        {!disabled && (
          <Tooltip title="Upload">
            <span>
              <IconButton
                onClick={handleUpload}
                size={iconSize}
                disabled={!selectedFile || uploading}
                color="secondary"
              >
                <UploadFileIcon fontSize={iconSize} />
              </IconButton>
            </span>
          </Tooltip>
        )}

        {/* Reset if there's an error or you want to unselect */}
        {error && (
          <Button
            onClick={handleReset}
            color="inherit"
            size="small"
            variant="outlined"
          >
            Reset
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ImageUpload;
