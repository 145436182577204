import React, { useState, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import { ArrowBack, ArrowForward, Close } from "@mui/icons-material";
import { AssetContent } from "../../../redux/reducers/assetReducer";

interface PreviewStoryDialogProps {
  open: boolean;
  content: AssetContent;
  onClose: () => void;
}

const PreviewStoryDialog: React.FC<PreviewStoryDialogProps> = ({
  open,
  content,
  onClose,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Memoize the bullets array to avoid ESLint warnings about react-hooks/exhaustive-deps
  const bullets = useMemo(() => {
    return content.story_items || [];
  }, [content.story_items]);

  const handlePrev = () => setCurrentIndex((idx) => Math.max(0, idx - 1));
  const handleNext = () =>
    setCurrentIndex((idx) => Math.min(bullets.length - 1, idx + 1));

  // Determine the most recent image URL at or before currentIndex
  const currentImageUrl = useMemo(() => {
    for (let i = currentIndex; i >= 0; i--) {
      if (bullets[i]?.image_url) return bullets[i].image_url;
    }
    return "";
  }, [bullets, currentIndex]);

  // Get the current bullet text
  const currentBullet = bullets[currentIndex] || { text: "" };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>{content.subject || "Story Preview"}</DialogTitle>

      <DialogContent
        sx={{
          maxHeight: "80vh",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            maxWidth: 800,
            backgroundColor: "#333",
            mb: 2,

            "&::before": {
              content: '""',
              display: "block",
              paddingBottom: "56.25%", // 16:9
            },
          }}
        >
          {currentImageUrl && (
            <Box
              component="img"
              src={currentImageUrl}
              alt="slide"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "fill",
              }}
            />
          )}
        </Box>

        <Typography variant="body1" sx={{ mb: 2 }}>
          <strong>Story Point Text: </strong>
          {currentBullet.text}
        </Typography>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "flex-end" }}>
        <IconButton onClick={handlePrev} disabled={currentIndex <= 0}>
          <ArrowBack />
        </IconButton>
        <Typography variant="body2" sx={{ mx: 1 }}>
          {currentIndex + 1} / {bullets.length}
        </Typography>
        <IconButton
          onClick={handleNext}
          disabled={currentIndex >= bullets.length - 1}
        >
          <ArrowForward />
        </IconButton>
        <IconButton onClick={onClose} color="error">
          <Close />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewStoryDialog;
